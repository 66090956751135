.makeStyles-paper-2 {
    width: 1280px !important;
    height: 95vh !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h5 {
    font-size: 14px !important;
}

a {
    text-decoration: none !important;
    color: black !important;
}

#active {
    background-color: #f5eaec;

}

#active h5 {
    font-weight: 700 !important;
}


#hide {
    display: none;
}

#show {
    display: block;
}

.row {
    font-family: 'poppins', sans-serif;
}


#state {
    display: none;
}

#checkmark-state {
    display: none;
}

#checkmark-state-on {
    display: block;
}

input {
    width: 80%;
    outline: none;
    border: none;
}

.modal-container {
    width: 100vw;
    height: 100vh;
    /* background-image: url('./assets/images/screenShot.PNG'); */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.modal-subcontainer {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.list {
    margin: 5px 0;

    display: grid;
    grid-template-columns: 1.5fr 9fr;
    align-items: center;
}

.list:hover {
    cursor: pointer;
}

.search {
    padding: 9px;
    font-size: 14px !important;
    display: flex;
}

.submit-btn {
    border-radius: 0px !important;
    background: #ed1c24 !important;
    font-weight: bold;
}

.searchArea {
    margin-left: 4px;
    border-radius: 0px !important;
}

.modal-container input {
    border-radius: 0px !important;
}

.modal-container input:focus {
    border: none;
    outline: none;
}

.option {
    height: 88vh !important;
    padding-left: 30px !important;
    width: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* width */
.option::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.option::-webkit-scrollbar-track {
    background: #f3f3f3;
    margin-top: 110px;
}

/* Handle */
.option::-webkit-scrollbar-thumb {
    background: #ed1c24;
    border-radius: 10px;
    height: 40px;
}

.fw-bold {
    font-weight: 700 !important;
    font-size: 18px !important;
    margin-top: 10px;
}



.icon-container {
    width: 20px;
    height: 20px;
}

.search-icon {
    width: 22px;
}

.checkmark {
    width: 20px;
}

.mk-list {
    background: #f5f6f7 0% 0% no-repeat padding-box;
    padding: 15px;
}

.mk-list:hover {
    cursor: pointer;
}

/* #selloption1::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 38px;
    bottom: -20px;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid #f5f6f7;
} */

/* Sales search Modal */
.search_sales_modal .option {
    padding-left: 20px !important;
}

.search_sales_modal ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.search_sales_modal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.search_sales_modal::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.search_sales_modal::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 768px) {
    .option {
        padding-left: 0px !important;
        height: 65vh !important;
        overflow-x: scroll !important;
    }

    .modal-container {
        height: 70vh;
        margin-top: 120px;
    }

    .option::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .option::-webkit-scrollbar-track {
        background: #f3f3f3;
        margin-top: 110px;
    }

    /* Handle */
    .option::-webkit-scrollbar-thumb {
        background: #ed1c24;
        border-radius: 10px;
        height: 40px;
    }

    .modal-container .list {
        padding-left: 0px !important;
        margin: 5px 0px !important;
    }
}