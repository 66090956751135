@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", Sans-Serif;
  scroll-behavior: smooth;
  color: black;
}

.car_hero_image_div {
  height: 540px;
  width: 116%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.happy-customers-title {
  font-size: 2rem;
  /* Customize as needed */
  font-weight: bold;
  /* Adjust weight */
  color: #333;
}

.row.d-flex.align-items-center.pb-3.dis-mobile {
  margin-bottom: -18px;
}

.img-res {
  overflow: hidden;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.navbar {
  background-color: white;
}

.nav-item {
  margin-right: 40px;
}

.navbar-brand {
  margin-right: 0;
  width: 27%;
  padding-left: 0;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }

  /* .navbar-expand-md .navbar-nav .nav-link {
    margin-left: -51px;
    margin-right: 89px;
  } */
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 70%;
    padding-left: 15px;
  }
}


.nav-btns {
  margin-right: 25px;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-btns-1 {
  border: 2px solid #ed1c24;
  border-radius: 50px;
  padding: 13px 27px 13px 27px;
  background: none;
  font-size: 18px;
  color: #ed1c24;
  font-weight: 700;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.nav-btns-1:focus,
.nav-btns-2:focus {
  outline: none;
  background: #ed1c24 !important;
  border: none !important;
}

.nav-btns-2 {
  border-radius: 50px;
  padding: 13px 27px 13px 27px;
  background: none;
  font-size: 18px;
  color: #fff !important;
  border: 2px solid white;
  font-weight: 700;
  background: #ed1c24;
}

.nav-btns-3 {
  border: 2px solid #ed1c24;
  border-radius: 0;
  padding: 13px 27px 13px 27px;
  background: none;
  font-size: 18px;
  color: #ed1c24;
  font-weight: 700;
}

.service-box p {
  margin-bottom: 23px;
  /* Adds the desired margin between the paragraph and the button */
}


.nav-btns-2:hover {
  background: #00000000;
  color: #000000 !important;
  border: 2px solid rgb(255, 0, 0);
  transform: scaleY(1.1) !important;
}

.nav-btns-1:hover {
  background: rgb(255, 0, 0);
  color: #ffff !important;
  border: 2px solid #000000;
  transform: scaleY(1.1) !important;
}


.text-icons {
  color: white;
  font-weight: 600;
}

/* Modern Icon Styles */
.ri-time-line,
.ri-mail-send-line,
.ri-phone-fill {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  color: #ffffff !important;
  transition: transform 0.3s ease;
}

.ri-time-line:hover,
.ri-mail-send-line:hover,
.ri-phone-fill:hover {
  transform: scale(1.2);
  color: #ffffff !important;
}

.service-box i {
  color: #ed1c24 !important;
  transition: all 0.4s ease;
}

.service-box:hover i {
  transform: scale(1.2) rotate(5deg);
  color: #ff4b4b !important;
}

/* Social Media Icons */
.share-icon i {
  font-size: 1.8rem;
  transition: all 0.3s ease;
  color: #ffffff !important;
}

.share-icon a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.share-icon a:hover {
  background: #ffffff;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-color: #ffffff;
}

.share-icon a:hover i {
  color: #d91414 !important;
}

/* Service Box Enhancement */
.service-box {
  padding: 40px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(237, 28, 36, 0.1);
  overflow: hidden;
  position: relative;
}

.service-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(237, 28, 36, 0.05), rgba(255, 75, 75, 0.05));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(237, 28, 36, 0.15);
  border-color: rgba(237, 28, 36, 0.3);
  background: linear-gradient(45deg, #ed1c24, #ff4b4b);
}

.service-box:hover::before {
  transform: scaleX(1);
}

.service-box:hover h3,
.service-box:hover p {
  color: #ffffff !important;
}

.service-box:hover i {
  color: #ffffff !important;
  transform: scale(1.2) rotate(5deg);
}

/* Call Button Enhancement */
.nav-btns-2 i {
  margin-right: 8px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.nav-btns-2:hover i {
  transform: rotate(15deg);
}

.nav-link {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ed1c24;
}

.nav-wrapper h1 {
  font-size: 24px !important;
  margin-bottom: 0.5rem !important;
}

.sec-text-2 p {
  margin-bottom: 1.5rem !important;
}

.header-text {
  margin-top: 5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 55px;
  color: #ffffff;
}

.card {
  border: none;
  /* Remove border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
  transition: transform 0.2s;
  /* Add a hover effect */
}

.card {
  border: 1px solid #ddd;
  /* Optional: Add a border */
  border-radius: 0;
  /* Remove border radius for rectangular shape */
  height: 100%;
  /* Ensure card takes full height */
  display: flex;
  flex-direction: column;
  /* Stack card content vertically */
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  /* Adjust width as needed */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  /* Change arrow color */
  border-radius: 50%;
  /* Optional: make arrows circular */
}

.carousel-control-prev {
  left: 10px;
  /* Adjust position */
}

a.btn.btn-danger {
  border-radius: 55px;
  margin-top: 20px;
  color: #ffffff !important;
}

.carousel-control-next {
  right: 10px;
  /* Adjust position */
}

.carousel-inner {
  border: 1px solid #ebcdcd;
  box-shadow: none;
}

.card:hover {
  transform: translateY(-5px);
  /* Lift effect on hover */
}

.border-t {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

section#Consultancy {
  background-color: rgb(255 255 255);
}

.border-text {
  text-align: center !important;
  padding-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  /* font-size: 55px; */
  color: #ffffff;
  /* border: 2px solid black; */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.card h3 {
  font-size: 2.5rem !important;
  /* Increase font size for numbers */
  margin: 0;
  /* Remove default margin */
}

.achievement-number {
  font-size: 3rem !important;
  /* Large font size */
  font-weight: bold;
  /* Bold text */
}

.service-box:hover h3,
.service-box:hover p {
  color: white;
  transition: color 0.3s ease-in-out;
}

.service-box img {
  max-width: 100%;
  /* Ensure images are responsive */
  height: auto;
  /* Maintain aspect ratio */
}

.service-box h3 {
  margin: 15px 0;
  /* Spacing above and below heading */
  font-size: 1.5rem !important;
  color: #333;
  /* Text color */
  font-weight: 900;

}

.p3 {
  color: red;
}

.service-box p {
  font-size: 1rem;
  /* Adjust font size */
  color: #666;
  /* Text color */
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 45px;
}

.image {
  font-family: "Poppins", sans-serif;
  width: 42%;
}

p.mb-0 {
  color: white;
}

span.ml-1 {
  color: white;
}

li.ms-3 {
  margin: 0 8px;
}

.py-3 {
  padding-top: 5px !important;
  padding-bottom: 0 !important;
}

.col.d-flex.justify-content-center {
  margin-bottom: 5px;
}

.mb-3,
.my-3 {
  margin-bottom: 0rem !important;
}

.card-text,
.para {
  font-size: 17px;
  color: #ffcbcb;
  font-weight: 500;
}

.card-text2 {
  font-size: 17px;
  color: #000;
  font-weight: 500;
}

.hero-text {
  padding-left: 0;
}

#service {
  width: 100%;
  padding: 40px 0;
  padding: 100px;
  background-color: #ffffff;
}

.card {
  border-radius: 0;
  border: 0;
  opacity: 1;

}

#backg {
  background: url(/public/assets/images/bg.png) no-repeat center bottom;
  min-height: 600px;
  display: flex;
  align-items: center;
}

.image-wrapper {
  color: black;
}

.iconDetails {
  width: 100%;
  height: auto;
}

img.img-res.img-res-car {
  padding-bottom: 80px;
  margin-right: 30px;
}

.sec-image-3 {
  width: 46%;
}

a.btn.btn-primary.ms-2.nav-btns-2.nav-btns-3 {
  color: #000000 !important;
}

.container2 {
  /* width: 100%;
            height: auto; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-left: 40px;
  /* margin-bottom: 5rem; */
}

.wrapper-call {
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 1400px) {
  .wrapper-call {
    max-width: 1320px;
  }
}



@media (max-width: 991px) {
  .wrapper-call {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .wrapper-call {
    max-width: 540px;
    padding: 0 20px;
  }
}

@media (max-width: 575px) {
  .wrapper-call {
    max-width: 100%;
    padding: 0 15px;
  }
}

#footer {
  margin-top: 0;
  background: #d91414;
  width: 100%;
}

#footer {
  margin: 0;
  padding: 24px 0;
  background: transparent;
  width: 100%;
}

.footer .row {
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .footer .wrapper-call {
    padding: 24px 15px;
  }
  
  #footer {
    padding: 0;
  }
  
  .footer .row {
    padding: 0;
  }
}

.text-bottom {
  font-size: 14px;
}

.share-icon {
  margin-top: 50px;
}

.text-bottom-1 {
  font-size: 14px;

}

.card {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
}

#service .card {
  width: 30rem !important;
  padding: 30px;
}

.footer {
  margin-top: 0;
  background: #d91414;
}

.text-muted,
.text-bottom,
.text-bottom-1,
li.nav-item.mb-2 {
  color: #ffffff !important;
  font-family: montserrat light, helvetica neue, Helvetica, Arial, sans-serif;
  font-size: inherit;
}

.fa {
  color: #ffffff;
}
path {
  color: red;
}
.pxs {
  font-size: 24px !important;
  font-weight: bold !important;
  color: #333;
  display: block;
  margin: 0;
  line-height: 1.2;
}

.pxs-2 {
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-top: 10px;
  display: block;
  color: #666;
}

@media only screen and (max-width: 900px) {
  .head-sec {
    height: auto !important;
  }

  .img-res-car {
    height: 400px !important;
  }

  .text-wrapper h1 {
    padding-left: 10px !important;
  }


  .hero-text {
    padding-left: 0;
    height: auto !important;
    padding-bottom: 60px;
  }

  .header-text {
    margin-top: 2rem;
    font-size: 42px;
  }

  .hero-text .nav-btns-2 {
    padding: 13px 90px 13px 90px;
  }

  .image {
    width: 100%;
  }

  /* Service area  */
  #service {
    padding: 40px 10px !important;
    height: auto !important;
  }

  #service .card {
    width: 100% !important;
    padding: 20px 10px !important;
    margin: 20px 0px;
  }

  .container2 {
    margin-left: 0px;
    margin-top: 2rem;
  }

  #Consultancy {
    height: auto !important;
  }

  .sec-image-3 {
    width: 100%;
  }

  .sec-text-2 {
    margin: 70px 40px !important;
  }

  .img-fluid {
    width: 100%;
  }


  .pxs {
    font-size: 20px !important;
  }

  .pxs-2 {
    font-size: 14px !important;
    margin-top: 8px;
  }
  div#header-wrapper {
    display: none;
}
  .wrapper-call {
    padding: 0 15px;
    min-height: 120px;
  }
  #phone-icon:before {
    content: "\efe9";
    color: black;
}
  .nav-btns-3 {
    border: 2px solid #ed1c24;
    border-radius: 0;
    background: none;
    padding: 2px 3px;
    font-size: 12px;
    color: #ed1c24;
    font-weight: 700;
    margin: 20px 1px;
  }

  .nav-item {
    margin: 0px;
  }

  .row.align-items-center.m-0 {
    display: none;
  }

  .nav-btns {
    margin-right: 0px;
    align-items: center;
    justify-content: center;
  }

  #footer {
    margin-left: 0;
    padding: 0;
    margin-top: 0;  /* Remove top margin */
  }

  .image-wrapper {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-wrapper img {
    width: 180px;
    height: 60px;
  }

  .nav-wrapper h1 {
    font-size: 16px !important;
    margin-bottom: 0.5rem !important;
    text-align: center;
  }

  .text-wrapper h1 {
    text-align: center;
    padding-top: 10px;
  }

  .dis-mobile {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
  
  .mob-btn {
    width: 100%;
    justify-content: center;
  }
  
  .col-9 {
    width: 100%;
    text-align: center;
    align-items: center;
  }
  
  .col-3 {
    width: 100%;
  }
}

@media screen and (min-height: 1080px) {
  .header-text {
    font-size: 80px;
  }

  .card-text,
  .para {
    font-size: 30px;
  }

  .hero-text .nav-btns-2 {
    font-size: 30px !important;
    margin-top: 20px;
  }

  #service {
    display: flex;
    align-items: center;
    justify-content: center
  }

  #service .card {
    width: 70% !important;
    padding: 30px;
    height: 710px;
    box-shadow: 0 16px 65px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    padding-bottom: 20px !important;
  }

  #service .card-title {
    font-size: 55px !important;
  }

  #Consultancy .sec-text-2 {
    margin-top: 17% !important;
    margin-left: 4% !important;
  }

  #Consultancy .sec-text-2 h1 {
    font-size: 55px !important;
  }

  #Consultancy .sec-text-2 p {
    font-size: 30px !important;
  }

  #service .nav-btns-1,
  #service .nav-btns-2 {
    font-size: 30px !important;
    margin-top: 20px;

  }

  .car_hero_image_div {
    height: 770px !important;
  }

  .nav-link {
    font-size: 24px !important;
  }


}

/* Enhanced vehicle card animations */
.vehicle-card {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

.vehicle-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(237, 28, 36, 0.05), rgba(255, 75, 75, 0.1));
  opacity: 0;
  transition: opacity 0.4s ease;
}

.vehicle-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(237, 28, 36, 0.15);
}

.vehicle-card:hover::before {
  opacity: 1;
}

.vehicle-card .card-body {
  padding: 1.8rem;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  position: relative;
  z-index: 1;
}

.vehicle-card .card-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
}

.vehicle-card .card-title i {
  color: #ed1c24;
  font-size: 1.6rem;
  transition: transform 0.3s ease;
}

.vehicle-card:hover .card-title i {
  transform: rotate(15deg);
}

.vehicle-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 15px 0;
}

.vehicle-detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.95rem;
  color: #666;
}

.vehicle-detail-item i {
  color: #ed1c24;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.vehicle-card:hover .vehicle-detail-item i {
  transform: scale(1.2);
}

.vehicle-card .btn-danger {
  background: #ed1c24;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.vehicle-card .btn-danger i {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.vehicle-card .btn-danger:hover {
  background: #ff4b4b;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(237, 28, 36, 0.2);
}

.vehicle-card .btn-danger:hover i {
  transform: translateX(5px);
}

.vehicle-image-wrapper {
  position: relative;
  overflow: hidden;
  height: 250px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Image Navigation Arrows */
.vehicle-image-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  background: rgba(237, 28, 36, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 3;
  border: none;
  color: white;
}

.vehicle-image-nav i {
  font-size: 1.2rem;
  color: white !important;
}

.vehicle-image-nav.prev {
  left: 10px;
}

.vehicle-image-nav.next {
  right: 10px;
}

.vehicle-image-wrapper:hover .vehicle-image-nav {
  opacity: 1;
}

.vehicle-image-nav:hover {
  background: rgba(237, 28, 36, 1);
  transform: translateY(-50%) scale(1.1);
}

.vehicle-image-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  z-index: 3;
}

/* Image transition animation */
.vehicle-image-wrapper img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  margin: auto;
  transition: opacity 0.3s ease;
}

.image-fade-enter {
  opacity: 0;
}

.image-fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.image-fade-exit {
  opacity: 1;
}

.image-fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.vehicle-badge {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(237, 28, 36, 0.9);
  color: white;
  padding: 8px 15px;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 2;
  transform: translateY(-5px);
  opacity: 0;
  transition: all 0.3s ease;
}

.vehicle-card:hover .vehicle-badge {
  transform: translateY(0);
  opacity: 1;
}

.vehicle-badge i {
  font-size: 1rem;
}

/* Vehicle card carousel controls */
.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  background: rgba(237, 28, 36, 0.8);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
  opacity: 1;
}

.carousel-control-prev {
  left: 20px;
}

.carousel-control-next {
  right: 20px;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background: rgba(237, 28, 36, 1);
  transform: translateY(-50%) scale(1.1);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px;
  height: 20px;
}

/* Enhanced hero section animations */
.hero-text h1 {
  animation: slideInFadeUp 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero-text p {
  animation: slideInFadeUp 1s cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
  animation-fill-mode: both;
}

.hero-text .nav-btns-2 {
  animation: slideInFadeUp 1s cubic-bezier(0.4, 0, 0.2, 1) 0.6s;
  animation-fill-mode: both;
}

@keyframes slideInFadeUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced service box animations */
.service-box {
  transform-style: preserve-3d;
  perspective: 1000px;
}

.service-box::before {
  transform-origin: center;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.service-box:hover {
  transform: translateY(-10px) rotateX(5deg);
}

.service-box img {
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.service-box:hover img {
  transform: scale(1.1) translateZ(20px);
}

/* Enhanced achievement box animations */
.achievement-box .card {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: #ffffff;
}

.achievement-box:hover .card {
  transform: translateY(-8px) scale(1.02);
  background: linear-gradient(135deg, #fff 0%, #fff5f5 100%);
  border: 2px solid #ed1c24;
}

.achievement-box .card h3 {
  color: #333333 !important;
  -webkit-text-fill-color: #333333 !important;
  transition: all 0.3s ease;
}

.achievement-box:hover .card h3 {
  color: #ed1c24 !important;
  -webkit-text-fill-color: #ed1c24 !important;
  transform: scale(1.1);
}

.achievement-box .card p {
  color: #ed1c24 !important;
}

/* Enhanced navbar animations */
.navbar {
  transition: all 0.3s ease;
}

.nav-link {
  position: relative;
  transition: color 0.3s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #ed1c24;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

/* Enhanced button animations */
.nav-btns-1, .nav-btns-2 {
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.nav-btns-1::before, .nav-btns-2::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease, height 0.6s ease;
}

.nav-btns-1:hover::before, .nav-btns-2:hover::before {
  width: 300px;
  height: 300px;
}

/* Enhanced scroll reveal animations */
.animate-on-scroll {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Enhanced consultancy section animations */
#Consultancy img {
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

#Consultancy:hover img {
  transform: scale(1.05) rotate(2deg);
}

/* Enhanced contact section hover effect */
#contact {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;  /* Remove bottom margin */
}

#contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(237, 28, 36, 0.05), rgba(255, 75, 75, 0.05));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

#contact:hover::before {
  transform: scaleX(1);
}

/* Enhanced social media icons animation */
.share-icon li {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.share-icon li:hover {
  transform: translateY(-5px) scale(1.1);
}

.share-icon li a {
  transition: all 0.3s ease;
}

.share-icon li:hover a {
  color: #ed1c24 !important;
  text-shadow: 0 0 10px rgba(237, 28, 36, 0.3);
}

/* Fix header contact text */
.mx-2 {
  color: #ffffff;
}

.mx-2 span {
  color: #ffffff !important;
}

.mx-2:hover i,
.mx-2:hover span {
  color: #ffffff !important;
  opacity: 0.8;
}

/* Fix running text color */
.running-text p {
  color: #ffffff !important;
}

.dis-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
}

.col-9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-3.mob-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Service box image fixes */
.service-box img {
  max-width: 100%;
  height: 200px; /* Fixed height for service images */
  object-fit: contain;
  margin: auto;
  display: block;
}

/* Consultancy section image fix */
#Consultancy .img-fluid {
  height: 400px;
  width: 100%;
  object-fit: contain;
  background-color: #ffffff;
}

/* Happy customers section image fix */
.happy-customers-image {
  height: 300px;
  width: 100%;
  object-fit: contain;
}

/* Hero section image fix */
.img-res.img-res-car {
  width: 100%;
  padding: 20px;
}

@media (max-width: 768px) {
  .vehicle-image-wrapper {
    height: 200px;
  }
  
  .service-box img {
    height: 150px;
  }
  
  #Consultancy .img-fluid {
    height: 300px;
  }
  
  .happy-customers-image {
    height: 250px;
  }
  
  .img-res.img-res-car {
    height: 300px !important;
  }
}

.head-sec {
  position: relative;
  overflow: hidden;
}

.head-sec .wrapper-call {
  position: relative;
  z-index: 2;
}

.navbar .wrapper-call {
  display: flex;
  width: 100%;
}

.navbar .container-fluid {
  padding: 0;
}

/* Car Card Styles */
.car-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    height: 100%;
    margin: 0;
}

.car-card:hover {
    transform: translateY(-5px);
}

.car-image {
    position: relative;
    height: 220px;
    overflow: hidden;
}

.car-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.car-card:hover .car-main-image {
    transform: scale(1.05);
}

.new-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    background: #dc3545;
    color: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 0.8rem;
}

.car-content {
    padding: 1.5rem;
}

.car-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
}

.car-specs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.spec-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.9rem;
}

.spec-item svg {
    color: #dc3545;
}

.car-price {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
}

.currency {
    font-size: 1rem;
    color: #666;
    margin-right: 0.25rem;
}

.view-details-btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background: #dc3545;
    color: white;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.view-details-btn:hover {
    background: #c82333;
    color: white;
}

/* Section Styles */
.border-text {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.border-text:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background-color: #dc3545;
}

/* Loading and Error States */
.no-results {
    text-align: center;
    padding: 3rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.no-results svg {
    margin-bottom: 1rem;
}

.no-results p {
    margin: 0;
    color: #666;
}

/* Grid Layout */
.row.g-3 {
    margin-left: 0;
    margin-right: 0;
}

.col-md-4 {
    padding: 0.75rem;
}

@media (max-width: 768px) {
    .container-fluid {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .col-md-4 {
        padding: 0.5rem;
    }
}

/* View Full Stock Button */
.full-stock-btn {
    background-color: #dc3545;
    color: white;
    padding: 12px 25px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s ease;
    margin-top: 2rem;
}

.full-stock-btn:hover {
    background-color: #c82333;
    color: white;
    text-decoration: none;
}