.admin-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.admin-container h2 {
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 700;
}

.add-car-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.form-section {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.form-section h3 {
    color: #ed1c24;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-weight: 500;
    color: #495057;
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group textarea {
    min-height: 120px;
    resize: vertical;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #ed1c24;
    box-shadow: 0 0 0 2px rgba(237, 28, 36, 0.1);
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-item input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: #ed1c24;
}

.image-upload-label {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background: #ed1c24;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.image-upload-label:hover {
    background: #ff4b4b;
}

.image-upload-label input[type="file"] {
    display: none;
}

.image-preview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.preview-item {
    position: relative;
    aspect-ratio: 16/9;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ced4da;
}

.preview-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.submit-btn {
    background: #ed1c24;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-btn:hover {
    background: #ff4b4b;
    transform: translateY(-2px);
}

.submit-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
}

.alert {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-weight: 500;
}

.alert-success {
    background: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.alert-danger {
    background: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
    .admin-container {
        margin: 1rem;
        padding: 1rem;
    }

    .form-section {
        padding: 1rem;
    }

    .form-row {
        grid-template-columns: 1fr;
    }

    .features-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .submit-btn {
        width: 100%;
    }
} 