* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.nav-link,
.navbar-brand {
  background: none;
  border: none;
}

.sale-text {
  letter-spacing: 0.96px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}
.fa {
  color: #ff0000 !important;
}
.buy-text {
  letter-spacing: 0.96px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600 !important;
}

#sellCount {
  letter-spacing: 0.3px;
  width: 48px;
  height: 23px;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
  font-size: 14px;
  background: #959594;
  padding: 1px 7px
}

#purchaseCount {
  letter-spacing: 0.3px;
  width: 48px;
  height: 23px;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff !important;
  font-size: 14px;
  background: #959594;
  padding: 1px 7px
}

.dashboard_box .box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), #edf1f4);
  border-radius: 40px;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1), -15px -15px 20px #fff;
  margin-bottom: 40px;
  display: none;
}

button:focus {
  border: none !important;
  outline: none !important;
}



/* .range1 {
  width: 400px;
  height: 15px;
  appearance: none;
  background: #edf1f4;
  outline: none;
  border-radius: 15px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1), -5px -5px 10px #fff,
    inset 5px 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
} */

/* .range1::-webkit-slider-thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #0074cf;
  box-shadow: -407px 0 0 400px #27a0ff;
  cursor: pointer;
} */

.dashboard_box #rangeValue {
  position: relative;
  text-align: center;
  width: 60px;
  font-size: 1.25em;
  color: #fff;
  background: #27a0ff;
  margin-left: 15px;
  border-radius: 25px;
  font-weight: 500;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px #fff,
    inset 5px 5px 10px rgba(0, 0, 0, 0.1),
    inset -5px -5px 5px rgba(255, 255, 255, 0.05);
}

.dashboard_box .middle {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-top: 40px;
  display: inline-block;
}

.dashboard_box .slider {
  position: relative;
  z-index: 1;
  height: 10px;
  margin: 0 15px;
}

.dashboard_box .slider>.track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #ececec;
}

.dashboard_box .slider>.range {
  position: absolute;
  z-index: 2;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #ed1c24;
}

.dashboard_box .slider>.thumb {
  position: absolute;
  z-index: 3;
  width: 30px;
  height: 30px;
  background-color: #27a0ff;
  border-radius: 50%;
}

.dashboard_box .slider>.thumb.left {
  left: 25%;
  transform: translate(-15px, -10px);
}

.dashboard_box .slider>.thumb.right {
  right: 25%;
  transform: translate(15px, -10px);
}

.dashboard_box .range2 {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 10px;
  width: 100%;
  opacity: 0;
}

.dashboard_box .range2::-webkit-slider-thumb {
  pointer-events: all;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  cursor: pointer;
  -webkit-appearance: none;
}

.dashboard_box #multi_range {
  margin: 0 auto;
  background-color: #27a0ff;
  border-radius: 20px;
  margin-top: 20px;
  text-align: center;
  width: 90px;
  font-weight: 500;
  font-size: 1.25em;
  color: #fff;
}

.price-wrapper {
  width: 137px;
  height: 30px;
  border: 0.20000000298023224px solid #a0a0a0;
  border-radius: 5px;
  opacity: 1;
  padding: 5px;
  margin-left: 12px;
  margin-top: 14px;
}

.label-wrapper {
  font: 'Poppins', sans-serif;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a0a0a0;
  text-transform: lowercase;
  opacity: 1;

}

#toggle_tst {
  position: absolute;
  width: 250px;
  height: 250px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 16px #7f7f7f4e;
  opacity: 1;
  background: #fff;
  margin: 20px;
  padding: 22px;
  right: 0rem;
  top: 70px;
}

.img-profile {
  text-align: center;
}

.text-profile {
  text-align: center;
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.para-profile {
  text-align: center;
  font: normal normal medium 11px/17px Poppins;
  letter-spacing: 1.54px;
  color: #ed1c24;
  text-transform: uppercase;
  opacity: 1;
  font-size: 11px;
}

.edit-profile {
  text-align: center;
  font: normal normal medium 13px/20px Poppins;
  letter-spacing: 0px;
  color: #818181;
  text-transform: uppercase;
  opacity: 1;
  font-size: 13px;
  padding-top: 26px;
}

.btn-profile {
  padding-top: 12px;
}


.dashboard_box .navbar_icons {
  justify-content: right;
  align-items: center;
  padding-left: 200px;
}

.dashboard_box .navbar_icons .marg-icons {
  margin-right: 30px;
  background: none;
  border: none !important;
}

/* .dashboard_box .underlineHover{
    transition: all 0.3s ease-in-out;
}
.dashboard_box .underlineHover:hover{
    border-bottom: 3px solid #ed1c24 !important;
    padding-bottom: 10px;
} */
.dashboard_box nav {
  box-shadow: 0px 8px 16px #7f7f7f4e;
}

.dashboard_box .dashboard_hero {
  height: 300px;
  background-color: #fcf0f5;
  width: 100% !important;
}

.dashboard_box .dashboard_hero .hero_search_div {
  height: 152px;
  background: rgba(255, 255, 255, 0.555);
  width: 70%;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 40px;
  box-shadow: 0px 8px 16px #7f7f7f4e;
}

.dashboard_box .nav-item button:focus {
  border: none !important;
  outline: none !important;
}

.dashboard_box .nav-item {
  margin-right: 30px !important;
}

/* .dashboard_box .nav-link {
  color: #959494 !important;
} */


.dashboard_box .dashboard_hero .form-control {
  width: 440px;
  height: 100px;
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
}

.dashboard_box .dashboard_hero .hero_input {
  height: 105px;
  border-right: 1px solid rgba(177, 173, 173, 0.767);
  background: transparent;
}

.dashboard_box .dashboard_hero .form-control:focus {
  border: none;
  outline: none;
}

.dashboard_box .dashboard_hero .hero_input_bottom button {
  letter-spacing: 2.6px;
  font-size: 13px/50px;
  font-family: 'Poppins', sans-serif;
  color: #818181;
}

.dashboard_box .dashboard_hero .hero_input_bottom button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.dashboard_box .hero_input img {
  height: 25px;
  width: 25px;
  margin: auto 10px auto 30px;
}


.dashboard_box .hero_input_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.dashboard_box .hero_input button {
  width: 160px;
  background-color: #ed1c24 !important;
  position: absolute;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 7px 5px;
  font: normal normal bold 18px/45px "Poppins";
  margin: 10px 40px 20px 5px;
  height: 60px;
  box-shadow: 0px 8px 16px #7f7f7f4e;
  transition: all 0.2s ease-in-out;
}

.dashboard_box .hero_input button:hover {
  background-color: #ed1c24 !important;
  color: white;
  transform: scale(1.1);
}

#loc-wrapper:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.dashboard_box .hero_input input {
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: transparent;
}

.dashboard_box .hero_input input:focus {
  border: none;
  outline: none;
}


/* start of purchase dashboard content section  */
.sidebar_contentbox h2 {
  font-size: 18px !important;
  font-weight: 500;
}

.purchase_dashboard_content .sidebar_contentbox .sidebar_budget .sidebar_details label {
  width: 52px;
  font-size: 10px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchase_dashboard_content .sidebar_contentbox .sidebar_budget .sidebar_details {
  width: 210px !important;
}



.purchase_dashboard_content .sidebar_contentbox .sidebar_budget .sidebar_details input {
  padding: 5px 10px;
  border: 0.20000000298023224px solid #A0A0A0;
  border-radius: 5px;
  width: 147px;
  font-size: 14px;
  font-weight: 600;
  font: normal normal medium 14px/38px "Poppins" !important;
  height: 30px;
  color: black !important;
}

.purchase_dashboard_content .sidebar_contentbox .sidebar_budget .sidebar_details span::after {
  content: "PKR";
  position: absolute;
  right: -23px;
  font-size: 10px;
  font-weight: 500;
  font: normal normal medium 14px/38px "Poppins" !important;
  padding: 9px 10px;
}

.sidebar_contentbox .sidebar_cities {
  display: flex;
  flex-direction: column;
}

.sidebar_contentbox input[type=checkbox] {
  width: 20px;
  height: 15px;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  accent-color: red;
}

.sidebar_contentbox input[type=checkbox]:checked {
  background: #ED1C24 0% 0% no-repeat padding-box;
}

.sidebar_contentbox label {
  font-size: 15px;
  padding: 10px 0px 10px 0px;
  font-weight: 500;
  color: #a0a0a0;
}

.sidebar_contentbox .sidebar_details {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.sidebar_contentbox .sidebar_details span {
  color: #818181;
  font-weight: 500;
  font-size: 15px;
  background: #ffffff;
  border-radius: 5%;
  text-align: center;
  box-shadow: 0px 3px 3px #00000017;
}

.sales_content_span {
  color: #818181;
  font-weight: 500;
  font-size: 15px;
  background: #ffffff;
  width: 20px !important;
  border-radius: 5%;
  text-align: center;
  box-shadow: 0px 3px 3px #00000017;
}

.sidebar_contentbox .search-button {
  width: 210px;
  height: 38px;
  margin-top: 10px;
  font: normal normal 600 13px/20px "Poppins";
  color: #555555;
  letter-spacing: 1.17px;
}

.sidebar_contentbox .sidebar_color input[type=checkbox] {
  margin-right: 22px;
}

.sidebar_contentbox .sidebar_color label {
  position: relative;
}


.carousel-item .d-block {
  width: 100%;
  padding: 5px 50px;
}
section.container-fluid.my-5 {
  background: #fff url(/public/assets/images/bg2.png) no-repeat left 20%;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 350px;
}
.sidebar_contentbox .sidebar_color .color_circle {
  content: '';
  display: inline-block;
  position: absolute;
  left: 33px;
  top: 13px;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #ed1c24;
  border: 1px solid grey;
}

/* End of purchase dashboard content section  */

/* Listing css  */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");



.underlineHover:after {
  display: block;
  margin-top: 1rem !important;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ed1c24;
  content: "";
  transition: width 0.2s;
}

.active_navitem:after {
  display: block;
  margin-top: 1rem !important;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ed1c24;
  content: "";
  transition: width 0.2s;
}


.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
  padding: 1px 0px;
}




.marg-icons {
  margin-right: 43px;
  margin-top: 12px;
}

.outer-wrapper {
  display: table;
  height: 400px;
  /* margin: auto; */
  width: 100%;

  overflow: hidden;
}

.inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 1600px;
  height: 300px;
  background: rgba(253, 248, 249, 1) 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow: scroll;
}

.filter-wrapper {
  width: 928px;
  height: 152px;
  margin: auto;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px rgba(205, 208, 226, 0.2);
  opacity: 1;
}

.nosubmit {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  margin: 0 0 30px;
}

.dashboard_box input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
}

.dashboard_box input[type="search"]::placeholder {
  color: #bbb;
}

form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-right: 2px solid rgba(251, 239, 241, 1);
  border-bottom: 1px solid rgba(251, 239, 241, 1);

  width: 100%;
  height: 108px;
  padding: 9px 4px 9px 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

#loc-wrapper {
  padding: 14px 30px 14px 0px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(251, 239, 241, 1);
  background-color: transparent;
}

.hero_search_div #loc-wrapper {
  padding: 14px 30px 14px 0px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(251, 239, 241, 1);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_search_div .MuiSelect-select.MuiSelect-select {
  padding: 10px 40px 10px 20px;
  width: 155px !important;
  color: black;
  background: white !important;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.btn-wrapper {
  padding: 31px 22px;
  border-bottom: 1px solid rgba(251, 239, 241, 1);
}


.advan-wrapper {
  text-align: center;
  margin-top: -1rem;
}

.advan-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-13) / var(--unnamed-line-spacing-50) var(--unnamed-font-family-poppins);
  text-align: center;
  font: normal normal medium 13px/50px Poppins;
  letter-spacing: 2.6px;
  color: rgba(129, 129, 129, 1);
  text-transform: uppercase;
  opacity: 1;
}

.noti-image {
  background: #fcf0f1 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  padding-left: 13px;
  width: 40px;
  height: 40px;
  padding-top: 7px;
}

.sort-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-wrapper a {
  font: normal normal 600 15px/50px "Poppins";
  margin-left: 5px;
}

.wrap-flex {
  justify-content: space-between;
}

.wrap-flex-2 {
  display: flex;
  justify-content: space-evenly;
}

.table-text-0,
small {
  font-size: 11px;
}

.table-text-1 {
  font-size: 12px;
  padding-left: 34px;
}

.table-text {
  font-size: 12px;
  margin-top: 12px;
  padding-left: 30px;
}

.bor-wrap {
  border: none;
}

.bor-wrap p {
  font-weight: 500;
}

.noti-image img {
  padding-top: 4px;
}


/* Sales data css  */
.dashboard_box .sales_data_wrapper {
  height: auto;
  width: 900px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f3f3f3;
  transition: 0.3s all !important;
}

.dashboard_box .sales_data {
  padding: 25px 0px;
}

.dashboard_box .sales_data:hover {
  cursor: pointer;
  background-color: #f9f7ff;
}

.dashboard_box .sales_data_image img {
  width: 29px;
  height: 31px;
  border-radius: 50%;
  margin-right: 20px;

}

.dashboard_box .sales_data_image {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.sales_data_details {
  width: 240px;
}

.dashboard_box .sales_data_wrapper .sales_data_details h2 {
  font-size: 17px;
  font-weight: 600;
}

.dashboard_box .sales_data_wrapper .sales_data_details h2,
.dashboard_box .sales_data_wrapper .sales_data_details span {
  font-weight: 600;
}

.dashboard_box .sales_data_wrapper .sales_data_details .sales_data_city {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  letter-spacing: normal;
}

.dashboard_box .sales_data_wrapper .sales_data_details p {
  font: normal normal normal 12px/18px "Poppins";
  color: #00000080;
  letter-spacing: 1px;
}

.dashboard_box .sales_data_wrapper .sales_data_second_content {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 10px;
  width: 140px;
}

.search_logo {
  width: 20px;
  height: 20px;
}

.sales_data_km {
  margin-left: 2rem !important;
  font: normal normal medium 12px/18px "Poppins";
}

.sales_show_content,
.purchase_show_content {
  width: 900px;
}

.sales_data_second_content {
  font-size: 14px;
}

.sidebar_contentbox .search-button:hover {
  transform: scale(1.1);
}

.sales_data_color {
  position: relative;
  margin-right: 30px !important;
  background: #f2f2f2;
  border-radius: 50px;
  padding-left: 10px !important;
}

.sales_data_color p {
  font-size: 12px;
  font-weight: 500;

}

.sales_data_color span {
  content: '';
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 12px;
  width: 17px;
  height: 17px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 10.5px;
  background-color: #ed1c24;
  box-shadow: 0px 8px 16px #00000033;
}



.tooltip.tooltiptext {
  width: 160px;
  bottom: 80%;
  left: 40%;
  margin-left: 80px;
}

/* Start of buy dashboard style  */
.dashboard_box .purchase_data_wrapper {
  height: auto;
  width: 925px;
  display: flex;
  align-items: center;
}

.dashboard_box .sales_data {
  padding: 25px 0px;
}

.dashboard_box .purchase_data_image img {
  width: 29px;
  height: 31px;
  border-radius: 50%;
  margin-right: 20px;

}

.dashboard_box .purchase_data_image {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.purchase_data_details {
  width: 240px;
}

.dashboard_box .purchase_data_wrapper .purchase_data_details h2 {
  font-size: 16px;
}

.dashboard_box .purchase_data_wrapper .purchase_data_details h2,
.dashboard_box .purchase_data_wrapper .purchase_data_details span {
  font-weight: 600;
}

.dashboard_box .purchase_data_wrapper .purchase_data_details .purchase_data_city {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  letter-spacing: normal;
}

.dashboard_box .purchase_data_wrapper .purchase_data_details p {
  font: normal normal normal 12px/18px "Poppins";
  color: #00000080;
  letter-spacing: 1px;
}

.dashboard_box .purchase_data_wrapper .purchase_data_second_content {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 10px;
  width: 140px;
}

.search_logo {
  width: 20px;
  height: 20px;
}

.purchase_data_km {
  margin-left: 1rem !important;
  font: normal normal medium 12px/18px "Poppins";
  font-weight: 500;
  width: 150px !important;

}

.purchase_show_content,
.purchase_show_content {
  width: 900px;
}

.purchase_data_second_content {
  font-size: 14px;
}

.sidebar_contentbox .search-button:hover {
  transform: scale(1.1);
}

.purchase_data_color {
  position: relative;
  margin-right: 30px !important;
  background: #f2f2f2;
  border-radius: 50px;
  padding-left: 10px !important;
  height: 41px;
}

.purchase_data_color p {
  font-size: 12px;
  font-weight: 500;

}

.purchase_data_color .purchase_color1 {
  display: inline-block;
  width: 17px;
  height: 17px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 10.5px;
  box-shadow: 0px 8px 16px #00000033;
  margin-right: 10px;
}

.purchase_data_color .purchase_color2::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 40px;
  top: 12px;
  width: 17px;
  height: 17px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 10.5px;
  background-color: #ed1c24;
  box-shadow: 0px 8px 16px #00000033;
}

.purchase_data_color .purchase_color3::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 65px;
  top: 12px;
  width: 17px;
  height: 17px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 10.5px;
  background-color: #ed1c24;
  box-shadow: 0px 8px 16px #00000033;
}

.sidebar_contentbox .MuiSlider-root {
  height: 10px !important;
  color: #ed1c24;
  width: 190px;
  padding-top: 30px;
}

.sidebar_contentbox .MuiSlider-rail {
  height: 10px !important;
}

.sidebar_contentbox .MuiSlider-track {
  height: 10px !important;
}

.sidebar_contentbox .MuiSlider-thumb {
  height: 20px !important;
  width: 20px !important;
  border: 1px solid #7b7373b0;
}


/* Saved Request Code Start here  */
.saved_request_wrapper {
  width: 100%;
  height: 138px;
  display: flex;
  align-items: center;
  justify-content: right;
  background: #FDF8F9;
}

.saved_request_wrapper .row {
  width: 86%;
  margin-right: 20px;
}

.saved_request_wrapper .dashboard_content_header {

  margin-left: auto;
  text-align: left;
}

.saved_request_wrapper h3 {
  font-size: 37px !important;
  font-weight: 600;
}

.dashboard_content_body {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid lightgrey;
  background: white !important;
}

.dashboard_content_body .saved_navigation {
  height: 100px;
  display: flex;
}

.dashboard_content_body .saved_navigation button {
  width: 200px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: #959494;
  text-align: left;
  align-items: center;
  outline: none !important;
}

/* Start of Sale details div   */
.sale_details_wrapper {
  width: 100%;
  height: 100%;
}

.sale_details_wrapper .sale_details_above_div {
  width: 19%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1 !important;

}

.sale_details_wrapper .sale_details_above_div img {
  width: 56px;
  height: 61px;
  content: url("./../../../public/assets/dashboard_images/alto.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.save_button_sale {
  right: 0;
}

/* Start of carousel code  */
.carousel-indicators {
  position: absolute;
  bottom: 20px
}

.carousel-indicators li {
  width: 100px;
  height: 100%;
  opacity: 0.8;
  border: 2px solid #ebcdcd;
  box-shadow: 0 10px 15px #ebcdcd;
}

.carousel-inner {
  height: 80vh !important;
  margin-top: 20px !important;
  border: 1px solid #ebcdcd;
  box-shadow: 0 10px 15px #ebcdcd;
}

.carousel-indicators li img {
  height: 70px !important;
  width: 110px;
}


/* End of carousel code  */

/* Start of other details div  */
/* .sale_details_others{
  height: 600px;
} */
.sale_details_others h1 {
  font-size: 22px;
  font-weight: 500;
}

.sale_details_others p {
  font-size: 16px;
  font-weight: 400;
  color: #7F7F7F;
}

/* End of other details div  */




/* Start of side div   */
.sale_details_side_data {
  width: 100%;
  height: 100%;
}

.sale_details_side_data .sale_details_side_div {
  width: 100%;
  margin-top: 20px;
  box-shadow: 0px 0px 27px #CDD0E28B;
  height: 600px;
  height: auto !important;
}

.sale_details_side_data .side_details_top_div {
  height: 220px;
  background: white;
  padding: 30px 40px;
}


.sale_details_side_data .side_details_top_div h1 {
  font-size: 26px;
  font-weight: 600;
  padding-left: 5px;
}

.sale_details_side_data .sale_details_side_map {
  background-image: url("./../../../public/assets/dashboard_images/Image 4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  padding: 30px 30px 20px 30px;
}

.sale_details_side_data .sale_details_side_map .side_map_div {
  background: white;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
}

.sale_details_side_data .sale_details_side_map .side_map_div a {
  text-transform: uppercase;
  color: #ED1C24 !important;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
}

/* start of social div in sale details  */
.sale_details_side_data .sale_details_side_social {
  height: auto;
  width: 100%;
  background-color: white;
  padding-bottom: 15px;
}

.sale_details_side_data .sale_details_side_social .request_number_div {
  padding: 20px 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.sale_details_side_data .sale_details_side_social .request_number_div h1 {
  color: #ed1c24;
  font-size: 16px !important;

}

.sale_details_side_data .sale_details_side_social .details_social_icons .py-3 h1 {
  color: #ed1c24;
  letter-spacing: 5.5px;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.details_social_icons {
  height: 100%;
}

.sale_details_side_data .sale_details_side_social .social_logos {
  padding: 15px 40px;
}

.sale_details_side_data .sale_details_side_social .social_logos img {
  height: 26px;
  width: 26px;
}

.social_logos .messenger_logo {
  content: url("./../../../public/assets/dashboard_images/messenger.png");
}

.social_logos .facebook_logo {
  content: url("./../../../public/assets/dashboard_images/facebook.png");
}

.social_logos .insta_logo {
  content: url("./../../../public/assets/dashboard_images/insta.png");
}

.social_logos .whatsapp_logo {
  content: url("./../../../public/assets/dashboard_images/whatsapp.png");
}

.messenger_logo:hover,
.facebook_logo:hover,
.insta_logo:hover,
.whatsapp_logo:hover {
  transform: scale(1.2, 1.1) !important;
  cursor: pointer !important;
  margin-bottom: 5px;
}

.map_logo {
  content: url("./../../../public/assets/dashboard_images/Group 2211.svg");
  height: 70px;
  width: 70px;
}

.contactNo_logo {
  content: url("./../../../public/assets/dashboard_images/Path 19270.svg");

}

.email_logo {
  content: url("./../../../public/assets/dashboard_images/Group 2229.svg");

}

.sale_detail_close_button {
  width: 100%;
  padding: 20px;
}

.sale_detail_close_button .close_button {
  height: 60px;
  width: 100%;
  padding: 20px;
  background: #FCF0F1;
  text-transform: uppercase;
  font-weight: 600;
  color: #ed1c24;
  font-size: 16px !important;
  margin-top: 50px;
}

.sale_detail_close_button .close_button:hover {
  transform: scale(1.1);
}




.sale_detail_notes {
  height: 35vh;
  padding: 40px 20px 30px 0px;
  margin-top: 30px;
  border-top: 2px solid #EAEAEA;
}

.sale_detail_notes h1 {
  font-size: 22px;
  font-weight: 500;
}

.sale_detail_notes p {
  font-size: 16px;
  font-weight: 400;
  color: #7F7F7F;
}



/* Buy details Div  */
.buy_details_div {
  padding: 20px 0px;
}

.buy_details_div h3 {
  text-transform: uppercase;
  color: #7F7F7F;
  font-size: 14px;
  font-weight: 500;
}

.buy_details_save_button {
  height: 50px;
  width: 50px;
  background: rgb(255, 255, 255);
  font-size: 13px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 27px #cdd0e28b;
  border-radius: 5px;

}

.buy_details_save_button img {
  height: 24px !important;
  width: 20px !important;
  content: url("./../../../public/assets/dashboard_images/save_logo.svg") !important;
}

.instant_button_sale {
  padding: 5px;
  background: #ED1C24;
  z-index: 1;
  height: 50px;
  width: 170px;
  left: 40px;
  top: 20px;

}

.sale_details_instant_button {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  color: white;
}




.buyactive:after {
  display: block;
  margin-top: 1rem !important;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ed1c24;
  content: "";
  transition: width 0.2s;
}

.saleactive:after,
.close_active:after {
  display: block;
  margin-top: 1rem !important;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ed1c24;
  content: "";
  transition: width 0.2s;
}

.buy_hover_div:hover {
  background: #fdfbfc;
  cursor: pointer;

}

button {
  background: none;
  border: none !important;
}


/* user account icon details  */
.user_account_icon .dropdown-item:active {
  background: #ed1c24;
}

.user_account_icon .dropdown-item:hover {
  background: #ed1c24;
  color: white !important;
}

.sales_dashboard_content .sidebar_show_btn,
.sales_dashboard_content .sidebar_close_btn {
  display: none;
}

.purchase_dashboard_content .sidebar_show_btn,
.purchase_dashboard_content .sidebar_close_btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .dashboard_box .dashboard_hero .hero_search_div {
    width: 100%;
    height: 206px;
  }

  .dashboard_box .dashboard_hero .hero_search_div .hero_inner {
    flex-direction: column !important;
  }

  .dashboard_box .dashboard_hero {
    height: 50vh !important;
  }

  .dashboard_box .dashboard_hero .hero_input {
    width: 100% !important;
  }

  .dashboard_box .dashboard_hero .form-control {
    width: 100% !important;
  }

  /* Sales Dashboard  */
  .sales_dashboard_content,
  .purchase_dashboard_content {
    position: relative;
  }

  .sales_dashboard_content .sidebar_contentbox {
    position: absolute;
    width: 300px;
    height: auto;
    background: white;
    padding-left: 20px !important;
    z-index: 1000;
    box-shadow: 0px 1px 27px #00000027;
    border-radius: 10px;
    transition: 0.6s ease-in-out;
  }

  .sales_dashboard_content .sidebar_show_btn,
  .sales_dashboard_content .sidebar_close_btn {
    display: block;
  }

  .sales_dashboard_content .sidebar_close_btn {
    padding-left: 220px;
  }

  .sales_dashboard_content .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .dashboard_box .sales_data_image {
    margin-right: 10px !important;
  }

  .sales_dashboard_content .col-lg-10 {
    padding-left: 2.5rem !important;
  }

  .dashboard_box .navbar_icons {
    padding-left: 0px;
    justify-content: center;
  }

  /* Buy Details   */
  .purchase_dashboard_content .sidebar_contentbox {
    position: absolute;
    width: 300px;
    height: auto;
    background: white;
    padding-left: 20px !important;
    z-index: 1000;
    box-shadow: 0px 1px 27px #00000027;
    border-radius: 10px;
    transition: 0.6s ease-in-out;
  }

  .purchase_dashboard_content .sidebar_show_btn,
  .purchase_dashboard_content .sidebar_close_btn {
    display: block;
  }

  .purchase_dashboard_content .sidebar_close_btn {
    padding-left: 220px;
  }

  .purchase_dashboard_content .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .sales_dashboard_content .col-lg-10 {
    padding-left: 2.5rem !important;
  }

  .sale_details_wrapper .sale_details_divs_mobile {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }

  .sale_details_wrapper .sale_details_above_div {
    width: 100% !important;
    align-items: center !important;
  }

  .sale_details_wrapper .carousel_image_container {
    margin: 10px !important;
  }

  .carousel-item .d-block {
    padding: 5px !important;
  }
}

.add-car-btn {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    background-color: #ed1c24;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.add-car-btn:hover {
    background-color: #ff4b4b;
    transform: translateY(-2px);
}