@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

input:focus {
    outline: none;
    border: 1px solid grey;
}

.text-wrapper h1 {
    font-size: 24px !important;
}

p {
    margin-bottom: 0px !important;
}

h3 {
    font-size: 18px !important;
}

html {
    height: 100%;
}


/* loading animation  */
.loader-container {
    position: relative;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #fff url("../public/assets/infinityani.svg") center no-repeat;
    z-index: 1;
}

.loader-container .loading {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ed1c24 !important;
    width: 100%;
}

.loader-container .loading h1 {
    letter-spacing: 2.5px;
    font-weight: 600;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}


.d-flex {
    display: flex;
}

.text-warning {
    color: #ed1c24 !important;
}

.bg-gray {
    opacity: 1;
}

.text-para1 {
    position: absolute;
    top: 39px;
    left: 34px;
    font-size: 14px;
    font-weight: 600;
    font-family: sans-serif;
    color: rgba(0, 0, 0, 0.658);
    z-index: 10000;
}

.text-para {
    position: absolute;
    top: 15px;
    left: 21px;
    font-size: 14px;
    font-weight: 600;
    font-family: sans-serif;
    color: rgba(0, 0, 0, 0.658);
    z-index: 1000;
}

.text-para-2 {
    position: absolute;
    top: 291px;
    left: 435px;
    font-size: 14px;
    font-weight: 600;
    font-family: sans-serif;
    color: rgba(0, 0, 0, 0.658);
    z-index: 1000;
}

label {
    color: #000;
    font-size: 12px;
}

.nav-wrapper {
    display: flex;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid #ccc;
}

#grad1 {
    background-color: #9c27b0;
    background-image: linear-gradient(120deg, #ff4081, #81d4fa);
}

#msform {
    text-align: center;
}

#msform fieldset .form-card {
    /* background: white;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2); */
    padding: 40px 80px 100px 90px;
    border: none;
    /* box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative; */
}

#msform .fieldset-2 .form-card {
    /* background: white;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2); */
    padding: 20px 80px 150px 90px;
    border: none;
    /* box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative; */
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

#msform fieldset .form-card {
    text-align: left;
    color: #9e9e9e;
    padding-left: 60px;
}

#sellyourcar #fieldset-1 .form-card {
    text-align: left;
    color: #9e9e9e;
    padding-left: 60px;
    height: 61vh !important;
}

.ms-options-wrap>.ms-options>ul input[type=checkbox] {
    margin-right: 5px !important;
    position: absolute !important;
    left: 30px !important;
    top: 10px !important;
}

#field-1 #msform input,
#field-1 #msform textarea {
    padding: 0px 8px 4px 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 40px;
    margin-top: 5px;
    position: absolute;
    left: 10%;
    width: 96%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2c3e50;
    font-size: 16px;
    letter-spacing: 1px;
}

#fieldset-1 #msform input:focus,
#fieldset-1 #msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-weight: bold;
    color: #ed1c24;
    outline-width: 0;
    border-bottom: 1px solid #ccc;
}


#ms-opt-4 {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    margin-bottom: 40px;
    color: orange;
}

#fieldset-1 .ms-options-wrap,
.ms-options-wrap {
    box-sizing: border-box;
    width: 100%;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
}

#fieldset-1 .ms-options-wrap,
.ms-options-wrap {
    box-sizing: border-box;
}

#fieldset-1 .card {
    width: 100% !important;
}

#fieldset-1 button {
    width: 98% !important;
    margin-right: 4px;
}

#fieldset-1 #msform .action-button {
    width: 230px;
    background-color: #ed1c24 !important;
    position: absolute;
    left: 80%;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    font-family: "Poppins", sans-serif;
    margin: 10px 5px;
}

#field-2 input {
    font-size: 1rem !important;
}

/* working of field 2 */
#fieldset-2 .min-label {
    padding: 14px 22px;
    font-size: 14px;
    width: 75px;
    color: #00000096;
    background: #f5f6f7;
    height: 50px;
    margin-right: 3px;
    font-weight: 600;
}

#fieldset-2 .max-label {
    padding: 14px 22px;
    font-size: 14px;
    width: 80px !important;
    color: #00000096;
    background: #f5f6f7;
    height: 50px;
    margin-right: 3px;
    font-weight: 600;
}

/* Working of fieldset 3 */
#fieldset-3 input {
    font-size: 1rem !important;
}

#fieldset-3 textarea {
    width: 94% !important;
}

#fieldset-3 textarea:focus {
    outline: none !important;
    border: 1px solid #ccc !important;
}

#fieldset-3 .d-flex {
    margin-top: 30px;
    padding: 0px;


}

.fieldset-1 .myinput {
    background-color: beige !important;
}

option {
    font-size: 1rem !important;
    margin: 2px !important;
    color: black;
    height: 10px !important;
}

select option:checked {
    background: #f3363cfd;
    color: rgb(236, 255, 236);
}

option:hover {
    background-color: yellow !important;
}

.option_div {
    background: black !important;
}


/* working of sell fieldset 1 */
.sell-a-car #fieldset-1 button {
    width: 95% !important;
    background: #f5f6f7;
    color: #b6b4ab;
    font-weight: 400;
    border-radius: 0px;
    border: none;
    outline: none;
}

.sell-a-car #fieldset-1 .action-button {
    width: 130px;
    background-color: #ed1c24 !important;
    position: fixed;
    right: 4rem;
    bottom: 1.5rem;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    font-family: "Poppins", sans-serif;
    margin: 0px 5px;
    font-size: 14px !important;
}

.sell-a-car #fieldset-2 .action-button {
    width: 130px;
    background-color: #ed1c24 !important;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
}

.sell-a-car_bottom .col-3 {
    padding: 0px !important;
    margin-right: 50px;
}




#sellSelectCity .ms-options-wrap {
    width: 350px !important;
}

#sellSelectCity input[type='checkbox']:checked :after {
    content: '✔' !important;
    color: #ed1c24 !important;
    margin-bottom: 3px;
    position: absolute;
    top: -10.5px;
    right: -250px;
    transform: rotateZ('9deg');
}

#sellSelectCity .ms-options-wrap>.ms-options>ul input[type="checkbox"] {
    width: 20px;
    height: 20px;
    accent-color: #f5f6f7;
    color: red !important;
    border: 0 !important;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#sellSelectCity input[type='checkbox']:checked:after {
    left: 240px;
}





.strong-wrapp {
    font-family: "Poppins", sans-serif !important;

    font-family: system-ui;
    font-size: 16px;
    padding-left: 10px;
    margin-top: 38px;
    color: lightgrey;
}

.achor-wrap {
    list-style: none;
    margin-top: 38px;
}

.input-wrapp {
    border: 0 !important;
    padding: 10px !important;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    margin-right: 5px;
    font-family: "Poppins", sans-serif !important;
    font-size: 1rem !important;
    height: 50px;
}

textarea {
    width: 97%;
    height: 150px;
    border: 0 !important;
    padding: 10px !important;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    margin-right: 5px;
    font-family: "Poppins", sans-serif !important;
}

.input-wrapp-2 {
    border: 0 !important;
    padding: 10px !important;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    margin-right: 3px;
    font-family: "Poppins", sans-serif !important;
    height: 50px;
    font-size: 14px;
}

#msform .action-button:hover,
#msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #ed1c24;
}

#msform .action-button-previous {
    width: 100px;
    background: #616161;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt-1 {
    border: none;
    outline: 0;
    padding: 10px 30px 10px 88px;
    margin: 2px;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
}

select.list-dt {
    border: none;
    outline: 0;
    padding: 10px 325px 10px 10px;
    margin: 2px;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
}

.ms-options-wrap>button:focus,
.ms-options-wrap>button {
    border: none !important;
    padding: 10px 144px 10px 70px !important;
    margin: 2px !important;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box !important;
    opacity: 1;
    margin-right: 5px !important;
    width: 97% !important;
    font-size: 14px !important;
}

#langOptgroup {
    padding: 10px 125px 10px 10px !important;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box !important;
    opacity: 1;
}

.card {
    z-index: 0;
    border: none;
    border-radius: 0.5rem;
    position: relative;
}

.fs-title {
    font-size: 22px;
    color: #000000;
    margin-bottom: 40px;
    font-weight: 500;
    text-align: left;
}

.pos-1 {
    position: absolute;
    top: 41.3rem;
    right: 3.5rem;
    width: 230px;
    background-color: #ed1c24 !important;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 8px 5px;
    font-family: "Poppins", sans-serif;
    margin: 10px 5px;
    font-size: 16px;
}

.pos-2 {
    position: absolute;
    top: 48.2rem;
    right: 1rem;
    width: 230px;
    background-color: #ed1c24 !important;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    font-size: 16px;
    padding: 8px 5px;
    font-family: "Poppins", sans-serif;
    margin: 10px 5px;
}

.review {
    position: absolute;
    top: 56rem;
    right: -2rem;
    width: 230px;
    background-color: #ed1c24 !important;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    font-family: "Poppins", sans-serif;
    margin: 10px 5px;
}

#progressbar {
    margin-top: 10px;
    overflow: hidden;
    color: lightgrey;
    /* background: white; */
    height: 75px;
}

.prog-btn {
    padding-left: 75px;
}

.prog-2 {
    padding-left: 46px;
}

#progressbar .active {
    color: #000000;
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    float: left;
    position: relative;
    display: flex;
}

#progressbar #account:before {
    content: "1";
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#progressbar #personal:before {
    content: "2";
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#progressbar #payment:before {
    content: "3";
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c";
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: rgb(225, 222, 222);
    border-radius: 50%;
    margin: 25px auto 0px auto;
    padding: 2px;
}

#progressbar li:after {
    content: "";
    width: 507%;
    height: 2px;
    background: hsl(0, 0%, 91%) 0% 0% no-repeat padding-box;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #ed1c24;
}



.radio-group {
    position: relative;
    margin-bottom: 25px;
}

.display-block {
    display: block;
}

.radio {
    display: inline-block;
    width: 204;
    height: 104;
    border-radius: 0;
    background: lightblue;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    cursor: pointer;
    margin: 8px 2px;
}

.radio:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
    width: 10%;
    object-fit: cover;
}

.text-wrap {
    font-size: 18px;
    color: black;
    padding: 20px 21px;
}

.fs-2 {
    margin-top: 57px;
    font-size: 22px;
}

#min {
    width: 53px;
    padding: 0px 0px 13px 2px;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    height: 42px;
}

.min-rs {
    width: 53px;
    padding: 0px 0px 13px 2px;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    height: 42px;
}

.list-dt-3 {
    border: none;
    outline: 0;
    padding: 10px 500px 10px 23px;
    margin: 2px;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
}

/* .text-para-3 {
  position: absolute;
  top: 550px;
  left: 125px;
  font-size: 16px;
  font-weight: 600;
  font-family: sans-serif;
  color: #000;
} */
.wrap-1 {
    margin-left: 22px;
}

.wrap-2 {
    margin-left: 22px;
}

.wrap-3 {
    margin-left: 20px;
}

input.form-control,
label {
    margin-bottom: 0px !important;
}

/* submit css */
.title-text {
    text-align: center;
    font: normal normal 600 24px/33px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 24px;
    margin-top: 50px;
}

.list-dt-4 {
    border: none;
    outline: 0;
    margin: 0px 0px;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px 180px 10px 10px;
}

/* step 2 */

.wrap-pad {
    margin-top: 2rem;
    line-height: 30px;
}

.list-dt-5 {
    border: none;
    outline: 0;
    margin: 2px;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px 620px 10px 0px;
}



.fieldset-1 .pos-4 {
    position: absolute;
    top: 35.6rem !important;
    right: 4rem !important;
}

.pos-5 {
    position: fixed;
    bottom: 1.5rem;
    right: 5rem;
    z-index: 1000;
}

.review-1 {
    position: fixed;
    bottom: 1.5rem;
    right: 5rem;
    z-index: 1000;
}

.list-dt-6 {
    border: none;
    outline: 0;
    margin: 2px;
    /* UI Properties */
    background: #f5f6f7 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px 570px 10px 10px;
    font-size: 14px;
}

/* step 3 */

.input-11 {
    border: 0 !important;
    padding: 18px 44px !important;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    margin-right: 5px;
    font-size: 1rem;
    height: 50px;
}

.input-22 {
    width: 206% !important;
    height: 50px;
    border: 0 !important;
    padding: 18px 44px !important;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    margin-right: 5px;
}

.ms-options-wrap>.ms-options>ul li label {
    padding: 12px 0px 12px 60px !important;
    margin-top: 4px;
}

.ms-options-wrap>.ms-options>ul {
    padding-top: 10px !important;
}


#fieldset-1 .ms-options-wrap>.ms-options>ul label:hover,
#fieldset-2 .ms-options-wrap>.ms-options>ul label:hover {
    background: transparent !important;
}

#fieldset-1 .ms-options-wrap>.ms-options>ul li.selected label,
#fieldset-2 .ms-options-wrap>.ms-options>ul li.selected label {
    background-color: transparent !important;
}

#fieldset-1 .ms-options-wrap>.ms-options>ul input[type="checkbox"],
#fieldset-2 .ms-options-wrap>.ms-options>ul input[type="checkbox"] {
    width: 20px;
    height: 20px;
    accent-color: #f5f6f7;
    color: red !important;
    border: 0 !important;
    outline: none;

    /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}

#fieldset-1 .ms-options-wrap>.ms-options>ul input[type="checkbox"]:focus {
    outline: none;
    background-color: transparent !important;
}

/* .sell-a-car input[type='checkbox']:checked:after {
  content: '✔' !important;
  color: #ed1c24 !important;
  margin-bottom: 3px;
  position: absolute;
  top: -9.5px;
  left: 0px;
  transform: rotateZ(9deg);
} */

.make_button .ms-options {
    min-height: 0px !important;
    display: none !important;
}

.make_button .ms-search {
    display: none !important;
}

.make_button .css-i857na {

    -webkit-justify-content: center !important;
}

/* #fieldset-1 .ms-options-wrap>.ms-options>ul .seclected input[type="checkbox"]:checked, #fieldset-2 .ms-options-wrap>.ms-options>ul .seclected input[type="checkbox"]:checked {
  background-color: red !important;
  color: red !important;
} */
/* #fieldset-1 .ms-options-wrap>.ms-options>ul input[type="checkbox"]:hover, #fieldset-2 .ms-options-wrap>.ms-options>ul input[type="checkbox"]:hover{
  background-color: red !important;
  color: red !important;
  border: 0 !important;
} */

.multiselect {
    width: 200px;
}

.selectBox {
    position: relative;
}

.selectBox select {
    width: 100%;
    font-weight: bold;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    display: none;
    border: 1px #dadada solid;
}

#checkboxes label {
    display: block;
}

/* #checkboxes label:hover {
  background-color: red
} */

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    /* Could be more or less, depending on screen size */
}


#sellSelectCity .ms-options-wrap>.ms-options>ul li.selected label {
    background-color: #fef3f3 !important;
}

.pay {
    text-transform: uppercase !important;
    margin-left: 3px;
    font-weight: 700;
}

.set_availability .text-para-22 {
    position: absolute;
    top: 595px;
    left: 180px;
    font-size: 14px;
    font-weight: 600;
    font-family: sans-serif;
    color: rgba(0, 0, 0, 0.658);
    z-index: 1000;
}

.sell-a-car .MuiSelect-icon {
    right: 10px !important;
}

/* .set_availability .text-para-33{
  position: absolute;
  top: 693px;
  left: 108px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
} */
.sell-a-car #fieldset-3 .review-1 {
    width: 130px;
    background-color: #ed1c24 !important;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
}

.sell-a-car .set_availability {
    margin-top: 35px;
}

.sell-a-car .list-dt-5,
.sell-a-car .list-dt-6 {
    width: 972px;
    font-size: 14px;
}

.sec-text-2 h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

a:hover {
    text-decoration: none !important;
}

.sell-a-car .input-11 {
    width: 290px !important;
}

.MuiButton-label {
    font-weight: 500 !important;
    color: black;
    opacity: 60%;
    font-size: 15px;
}

/* Successfull component Start  */

.marg-wrapp {
    margin-top: 4rem;
}

.success_container .succes {
    width: 50%;
    margin: auto;
}

.success_container .succes-image {
    margin-bottom: 30px;
    margin-left: 8rem;
}

.success_container .succes-text {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.success_container .succes-p {
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
    font-size: 15px;
    margin-bottom: 2rem;
}

.success_container .succes-btn {
    padding-top: 14px;
    text-align: center;
    /* top: 653px; */
    /* left: 687px; */
    width: 230px;
    margin: auto;
    height: 50px;
    color: rgb(255, 255, 255) !important;

    /* UI Properties */
    background: #ed1c24 0% 0% no-repeat padding-box;
    transition: 0.3s all;
}

.success_container .succes-btn:hover {
    transform: scale(1.1);
}

.success_container .btn-text {
    padding-top: 12px !important;
    color: rgb(255, 255, 255) !important;
}

/* Successfull component End  */

#progressbar li.active .strong-wrapp {
    color: #000000;
}

.guess_number {
    background: #f5f6f7 0% 0% no-repeat padding-box;
    width: 325px;
    padding: 20px;
    text-transform: uppercase;
    color: #ed1c24;
    font-size: 14px;
    font-weight: 500;
}

.cropper-point {
    background: #ffffff !important;
    height: 12px !important;
    width: 12px !important;
    border-radius: 50% !important;
    border: 2px solid #ed1c24;
}

.cropper-line {
    background: #ed1c24 !important;
}

.cropper-view-box {
    outline: 1px solid #ed1c24 !important;

}

.cropper-point.point-nw {
    top: -6px !important;
    left: -7px !important;
}

.cropper_save_btn {
    background: #ed1c24 0% 0% no-repeat padding-box;
    color: white;
    width: 123px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px;

}



/* Error Page Handling  */

/*======================
    404 page
=======================*/


.page_404 {
    padding: 70px 0;
    background: #fff;
    font-family: 'Poppins', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #ed1c24;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -50px;
}

@media screen and (max-width: 768px) {
    .nav-wrapper {
        flex-direction: column !important;
        justify-content: center !important;
    }

    #msform fieldset .form-card {
        padding: 10px !important;
    }

    #msform #fieldset-1 .form-card .sell-a-car_bottom {
        flex-direction: column !important;
    }

    #msform #fieldset-1 .form-card .sell_a_car_row {
        margin-bottom: 0px !important;
    }

    #msform #fieldset-1 .form-card .mileage_div_margin {
        margin-left: 0px !important;
        margin-top: 15px !important;
        padding-right: 10px;
    }

    #msform #fieldset-1 .form-card .registration_city_div {
        height: 80px !important;
    }

    #msform #fieldset-1 .form-card .form-group {
        width: 100% !important;
    }

    .sell-a-car #fieldset-1 button {
        width: 100% !important;
        margin-bottom: 15px !important;

    }

    .pay {
        width: 200px !important;
    }

    #fieldset1 .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
    .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
        padding-right: 0px !important;
        width: 100% !important;
        margin-bottom: 10px;
    }

    .MuiSelect-select.MuiSelect-select {
        width: 100% !important;
        padding-right: 80px !important;
    }



    /* .MuiAutocomplete-hasClearIcon {
    width: 100% !important;
  } */

    #progressbar li {
        margin: 0px 10px;
    }

    #progressbar .prog-btn {
        padding-left: 6px;
    }

    .sell-a-car #fieldset-1 .action-button {
        right: 0rem !important;
        width: 100px;
        font-size: 10px !important;
    }

    .mileage_div_margin input {
        width: 250px !important
    }

    #fieldset-3 .form-card .col-9 {
        display: block !important;
    }

    #fieldset-3 .form-card .col-9 .text-wrap {
        padding: 0px 12px !important;
    }

    #fieldset-3 .form-card .col-9 .wrap-1,
    #fieldset-3 .form-card .col-9 .wrap-2,
    #fieldset-3 .form-card .col-9 .wrap-3 {
        margin: 15px 10px !important;
        width: 100%;
    }

    .input-22 {
        width: 289px !important;
        height: 50px;
        border: 0 !important;
        padding: 18px 44px !important;
        background: #f5f6f7 0% 0% no-repeat padding-box;
        margin-right: 5px;
    }

    .sell-a-car #fieldset-3 .review-1 {

        z-index: 1000 !important;
        width: 122px !important;
        right: 1rem;
        height: 40px;
    }

    .wrap-3 {
        margin-top: 10px;
        margin-bottom: 20% !important;
    }

    .fs-title {
        margin-bottom: 10px;
    }

    .success_container .succes {
        width: 100% !important;
    }

    .success_container .succes-image {
        margin-left: 0px !important;
    }

    .success_container .succes-image {
        width: 100% !important;
    }
}