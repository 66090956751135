/* Dashboard 2025 Modern Design */
:root {
  --primary-color: #d9534f;
  --primary-dark: #c9302c;
  --primary-light: #f2dede;
  --secondary-color: #5bc0de;
  --dark-color: #343a40;
  --light-color: #f8f9fa;
  --gray-color: #6c757d;
  --gray-light: #e9ecef;
  --success-color: #5cb85c;
  --warning-color: #f0ad4e;
  --danger-color: #d9534f;
  --sidebar-width: 280px;
  --sidebar-collapsed-width: 80px;
  --header-height: 70px;
  --border-radius: 10px;
  --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  --transition: all 0.3s ease;
}

/* Main Layout */
.dashboard-2025 {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #f4f7fa;
  font-family: 'Poppins', sans-serif;
}
.fa {
  color: #ff0000 !important;
}
/* Sidebar Styles */
.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  z-index: 1000;
}

.sidebar.collapsed {
  width: var(--sidebar-collapsed-width);
}

.sidebar-header {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid var(--gray-light);
}

.sidebar-logo {
  display: flex;
  align-items: center;
}

.sidebar-logo img {
  height: 40px;
  transition: var(--transition);
}

.sidebar.collapsed .sidebar-logo {
  display: none;
}

.sidebar-toggle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--light-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.sidebar-toggle:hover {
  background: var(--gray-light);
}

.sidebar.collapsed .sidebar-toggle i {
  transform: rotate(180deg);
}

.sidebar-menu {
  flex: 1;
  overflow-y: auto;
  padding: 20px 0;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin-bottom: 5px;
}

.sidebar-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 0;
  position: relative;
  text-align: left;
}

.sidebar-menu button:hover {
  background: var(--gray-light);
}

.sidebar-menu li.active button {
  background: var(--primary-light);
  color: var(--primary-color);
  font-weight: 600;
  border-left: 4px solid var(--primary-color);
}

.menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon img {
  width: 24px;
  height: 24px;
}

.sidebar.collapsed .menu-text {
  display: none;
}

.menu-badge {
  background: var(--gray-color);
  color: white;
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: auto;
}

.sidebar-menu li.active .menu-badge {
  background: var(--primary-color);
}

.sidebar.collapsed .menu-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 2px 5px;
  font-size: 10px;
}

.sidebar-footer {
  padding: 15px 20px;
  border-top: 1px solid var(--gray-light);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar.collapsed .user-details {
  display: none;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-color);
}

.user-details h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.logout-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: none;
  background: var(--light-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  color: var(--gray-color);
}

.logout-btn:hover {
  background: var(--primary-light);
  color: var(--primary-color);
}

.sidebar.collapsed .logout-btn span {
  display: none;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  margin-left: var(--sidebar-width);
  transition: var(--transition);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content.expanded {
  margin-left: var(--sidebar-collapsed-width);
}

.main-header {
  height: var(--header-height);
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 100;
}

.mobile-menu-toggle {
  display: none;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--dark-color);
}

.header-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: var(--dark-color);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-box {
  position: relative;
  width: 250px;
}

.search-box input {
  width: 100%;
  padding: 10px 15px;
  padding-right: 40px;
  border: 1px solid var(--gray-light);
  border-radius: 30px;
  background: var(--light-color);
  transition: var(--transition);
}

.search-box input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(217, 83, 79, 0.2);
}

.search-box button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--gray-color);
  cursor: pointer;
}

.user-dropdown {
  position: relative;
}

.user-dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.user-dropdown-toggle:hover {
  background: var(--gray-light);
}

.user-avatar-small {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-avatar-small i {
  font-size: 16px;
  color: var(--gray-color);
}

.user-dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 280px;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 16px;
  z-index: 1000;
}

.user-dropdown-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray-light);
  margin-bottom: 16px;
}

.user-dropdown-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: var(--gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.user-dropdown-avatar i {
  font-size: 32px;
  color: var(--gray-color);
}

.user-dropdown-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-color);
  margin-bottom: 4px;
}

.user-dropdown-role {
  font-size: 12px;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.edit-profile-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: var(--gray-color);
  text-decoration: none;
  border-radius: var(--border-radius);
  transition: var(--transition);
  margin-bottom: 8px;
}

.edit-profile-link:hover {
  background: var(--gray-light);
  color: var(--primary-color);
}

.logout-link {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border: none;
  background: transparent;
  color: var(--gray-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  border-top: 1px solid var(--gray-light);
}

.logout-link:hover {
  background: var(--primary-light);
  color: var(--primary-color);
}

/* Content Wrapper */
.content-wrapper {
  flex: 1;
  padding: 30px;
  overflow-y: auto;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .sidebar {
    transform: translateX(-100%);
    box-shadow: none;
  }
  
  .sidebar.mobile-open {
    transform: translateX(0);
    box-shadow: var(--box-shadow);
  }
  
  .main-content {
    margin-left: 0 !important;
  }
  
  .mobile-menu-toggle {
    display: block;
  }
  
  .search-box {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .header-title h1 {
    font-size: 20px;
  }
  
  .search-box {
    display: none;
  }
  
  .content-wrapper {
    padding: 20px 15px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sidebar-menu li button,
.header-actions > * {
  animation: fadeIn 0.3s ease;
}

/* Filter Section Styles */
.filter-section {
  background: white;
  border-radius: var(--border-radius);
  padding: 25px;
  margin: 20px 0;
  box-shadow: var(--box-shadow);
}

.filter-group {
  margin-bottom: 25px;
}

.filter-group:last-child {
  margin-bottom: 0;
}

.filter-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-color);
  margin-bottom: 15px;
}

.price-range {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.price-input {
  flex: 1;
  position: relative;
}

.price-input input {
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  font-size: 14px;
}

.price-input .currency {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--gray-color);
  font-size: 14px;
}

.range-slider {
  width: 100%;
  margin: 10px 0;
}

.range-slider input[type="range"] {
  width: 100%;
  height: 4px;
  background: var(--gray-light);
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition);
}

.range-slider input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}

.filter-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.filter-option {
  padding: 10px 15px;
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  cursor: pointer;
  transition: var(--transition);
  text-align: center;
  font-size: 14px;
}

.filter-option:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.filter-option.active {
  background: var(--primary-light);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.view-all-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background: var(--light-color);
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  color: var(--gray-color);
  font-size: 14px;
  cursor: pointer;
  transition: var(--transition);
}

.view-all-button:hover {
  background: var(--gray-light);
  color: var(--dark-color);
}

.create-alert {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: var(--primary-light);
  border-radius: var(--border-radius);
  margin: 20px 0;
}

.create-alert i {
  color: var(--primary-color);
  font-size: 20px;
}

.create-alert span {
  color: var(--dark-color);
  font-size: 14px;
}

.sort-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sort-label {
  color: var(--gray-color);
  font-size: 14px;
}

.sort-select {
  padding: 8px 15px;
  border: 1px solid var(--gray-light);
  border-radius: 8px;
  color: var(--dark-color);
  font-size: 14px;
  cursor: pointer;
  transition: var(--transition);
}

.sort-select:hover {
  border-color: var(--primary-color);
}

/* No Data Found Section */
.no-data-found {
  text-align: center;
  padding: 40px 20px;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.no-data-found img {
  width: 200px;
  margin-bottom: 20px;
}

.no-data-found h3 {
  color: var(--dark-color);
  font-size: 20px;
  margin-bottom: 10px;
}

.no-data-found p {
  color: var(--gray-color);
  font-size: 16px;
}

/* Car Listing Box Styles */
.cars-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.car-listing-box {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}

.car-listing-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.car-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
}

.car-info {
  flex: 1;
}

.car-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.3;
}

.car-details {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666;
  font-size: 14px;
}

.car-location {
  display: flex;
  align-items: center;
  gap: 5px;
}

.car-meta {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.car-transmission,
.car-mileage {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  font-size: 14px;
}

.instant-tag {
  margin-left: auto;
  background: #d9534f;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .cars-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }
  
  .car-listing-box {
    padding: 15px;
  }
} 