#toggle_tst {
  position: absolute;
  width: 250px;
  height: 250px;
  z-index: 999;
}

.tittle-head {
  font: var(--unnamed-font-style-normal) normal 600 37px/56px var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: center;
  font: normal normal 600 37px/56px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

#tabs {
  background-color: none;
  border: none;
}

.outer-wrapper-2 {
  height: 138px;
  background: #FDF8F9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account_section .ui-tabs .ui-tabs-nav {
  margin: 0;
  background: none;
  padding: 0.2em 0.2em 0;
  border: none;
}

.account_section .ui-tabs .ui-tabs-nav li {
  list-style: none;
  float: left;
  position: relative;
  top: 0;
  margin: 1px 0.2em 0 0;
  border-bottom-width: 0;
  padding: 0;
  white-space: nowrap;
  border: none;
  /* background: none; */
  color: black;
}

.account_section #tab-wrap {
  background: none;
}

.account_section .tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
}

.account_section .tab-group:after {
  content: "";
  display: table;
  clear: both;
}

.account_section .tab-group li button {
  display: block;
  text-decoration: none;
  padding: 15px;
  color: black;
  font-size: 20px;
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
}



.account_section .tab-group button {
  font: normal normal 500 16px/40px "Poppins";
  letter-spacing: 0.96px;
  text-transform: uppercase;
  opacity: 1;
}



.account_section p .req {
  margin: 2px;
  color: #1ab188;
}


.account_section p.active .req {
  opacity: 0;
}

.account_section p {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13) / 20px var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #7f7f7f;
  opacity: 1;
}

.account_section p.highlight {
  color: #000000;
}

.account_section input,
.account_section textarea {
  font-size: 22px;
  display: block;
  width: 100%;
  height: 100%;
  padding: 11px 10px;
  background-image: none;
  color: #000000;
  border-radius: 0;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;
  border: none;

  background: #f9fafc 0% 0% no-repeat padding-box;
}

.account_section input:focus,
.account_section textarea:focus {
  outline: 0;
  border-bottom: 4px solid #ed1c24;
}

.account_section .field-wrap {
  position: relative;
  margin-bottom: 40px;
}

.account_section .top-row:after {
  content: "";
  display: table;
  clear: both;
}

.account_section .top-row>div {
  float: left;
  /* width: 48%; */
  margin-right: 11%;
}

.account_section .top-row>div:last-child {
  margin: 0;
}

.account_section .button {
  border: 0;
  outline: none;
  border-radius: 0;
  padding: 15px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.5s ease;
  -webkit-appearance: none;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18) / var(--unnamed-line-spacing-45) var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 18px/45px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: var(--unnamed-color-ed1c24) 0% 0% no-repeat padding-box;
  background: #ed1c24 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #ed1c2433;
}

.account_section .form {
  padding: 40px;
  max-width: 600px;
  margin: 0px auto;
  border-radius: 4px;
  /* box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3); */
}

.account_section .button-block {
  display: block;
  width: 100%;
}

.account_section .forgot {
  margin-top: -20px;
  text-align: right;
}

.account_section .nav-btns-2 {
  width: 136px;
  height: 38px;
  background: #fcf0f1 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  text-align: center;
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 1.17px;
  color: #ed1c24;
  text-transform: uppercase;
  opacity: 1;
  padding-top: 8px;
}

.account_section .input_icon {
  position: relative;
}

.account_section .input_icon span {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ed1c24;
  font-size: 20px;
}

.account_section .input_icon img {
  height: 40px;
  width: 40px;
  margin-top: 15px;
  margin-right: 10px;
  filter: invert(138%) sepia(11%) saturate(8087%) hue-rotate(266deg) brightness(261%) contrast(45%)
}

.account_section .input_icon img:hover {
  cursor: pointer;
  color: #ed1c24;
  background-color: #03030354;
  border-radius: 50%;
}

.sel-wrapper p:hover {
  color: white !important;
}

.car_make_text {
  text-transform: capitalize !important;
}

/* Users area  */

.dashboard_content_header .action-button {
  background: #ed1c24;
  color: #fff;
  border: none;
  width: 187px;
  height: 60px;
  /* padding: 10px 20px; */
  /* border-radius: 5px; */
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-right: 300px;
}

/* .users_data_wrapper{
    width: 1030px !important
} */
.users_data_wrapper .users_data_table {
  height: 85px;
  border-bottom: 1px solid lightgray;
}

.users_data_wrapper .users_data_table .user_element {
  display: inline-block;
  width: 20%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
  padding-top: 14px;
  justify-content: center;
}

.users_data_wrapper .users_data_table .user_name {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000;
  padding-top: 10px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.users_data_wrapper .users_data_table .user_designation {
  color: #ed1c24;
  padding-top: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users_data_wrapper .fa {
  color: #7F7F7F;
  font-size: 21px !important;
  font-weight: 600;
  transition: all 0.3s ease;
}

.users_data_wrapper .fa:hover {
  color: #ed1c24;
  cursor: pointer;
  transform: scaleY(1.3);

}

/* Add users Modal3  */
.add_user_div {
  padding: 30px;
  width: 100%;
}

.add_user_div input {
  width: 280px;
  height: 45px;
  padding: 25px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #ed1c24 !important;
  margin-bottom: 20px;
  outline: none;
  border-radius: none;
}

.add_user_div input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.add_user_div input::placeholder {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #a0a0a0;
}

.add_user_div .MuiMenuItem-root {
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;

}

.add_user_div .MuiInputBase-root {
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;
  border: 2px solid #F1F2F5;
  background: #ffffff !important;
  margin-top: 5px;
  color: black !important;
}

.add_user_div .MuiSelect-select.MuiSelect-select {
  width: 100% !important;
  height: 30px !important;
  display: flex;
  align-items: center !important;
}

.add_user_div .add_user_header h2 {
  font-size: 36px;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;
}

.add_user_div .form-control {
  border: none !important;
  height: 45px !important;
  background: #E8EAEF79 !important;
  padding-right: 10px;
  border-radius: 0px;
}

.add_user_div .form-group label,
.sel-wrapper label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: black;
  padding-left: 13px;
}

.add_user_div .add_user_button button {
  width: 137px;
  height: 60px;
  font-weight: 600;
}

.add_user_div .add_user_button button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}


/* Login style  */
.login_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.login_wrapper .login_image {
  width: 600px;
  height: 100vh;
}

.login_wrapper .login_image img {
  width: 100%;
  height: 100vh;
}

.login_wrapper .login_form {
  width: 500px;
  height: 70vh;
  border-radius: 10px;
  margin: 0px auto !important;

}

.login_form .login_form_header h1 {
  margin: 0 0 24px 0;
  font-size: 37px;
  text-align: left;
  font-weight: 600;

}

.login-form .form-input-material {
  margin: 12px 0;
  width: 490px !important;
  height: 90px;
  box-shadow: 0px 0px 16px #DBDBDBA0;
  margin: 20px 10px;
  transition: 0.5s ease-in-out;
}

.login-form .btn {
  width: 100%;
  margin: 18px 0 9px 0;
  padding: 8px 20px;
  position: relative;
  border-radius: 0;
}

.login-form input {
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  padding: 10px 20px !important;
  font-size: 18px;

}

.form-input-material .position-absolute {
  right: 25px;
  top: 30px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ed1c24;
  font-weight: 500;
}



.login-form button,
.login-form input {
  font: inherit;
  outline: none;
}

.form-input-material {
  position: relative;
  transition: 0.5s ease-in-out;
}

.form-input-material input:focus {
  border-left: 15px solid rgb(230, 14, 14);
}


/* .form-input-material::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  background-color: #ed1c24;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
} */
.form-input-material:focus-within::before {
  transform: scaleY(1);
}

.form-input-material .form-control-material {
  padding: 0.5rem 0;
  background: none;
  border: none;
}

.form-input-material .form-control-material:focus~label,
.form-input-material .form-control-material:not(:placeholder-shown)~label {
  transform: translateY(-100%) scale(0.8);
  color: #ed1c24;
}

.form-input-material label {
  position: absolute;
  top: 1.7rem;
  left: 30px;
  transition: 0.3s;
  transform-origin: left;
  font-size: 18px;
}

.login_button {
  color: white;
  background: #ed1c24;
  transition: 0.3s;
  overflow: hidden;
  width: 160px;
  height: 50px;
  margin-right: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.login_button:hover {
  transform: scale(1.1);
}

.btn-ghost:hover::before {
  transform: translateX(100%);
}

.admin_dashboard_container {
  height: 100vh;
}

.dashboard_top_header {
  height: 100px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  /* margin-bottom: 20px; */
  /* border-bottom: 1px solid #ed1c24; */
  box-shadow: 0px 0px 16px #DBDBDBA0;
}

.admin_dashboard_container .col-lg-3 {
  height: auto;
  box-shadow: 0px 0px 10px #DBDBDBA0;
  overflow: hidden !important;
  padding: 0px !important;
}

.admin_dashboard_container .admin_sidebar_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 0px 10px #dbdbdb81;
  margin-bottom: 5px;
}

.admin_dashboard_container .dashboard_content_admin {
  padding: 1px 20px !important;
  height: auto;
}

.admin_dashboard_container .admin_sidebar_item button:hover,
.admin_dashboard_container button:hover {
  background: #ed1c24;
  color: white !important;
  transform: scale(1.1);
}

.admin_dashboard_container .dashboard_admin_content_body .form-group {
  margin: 10px 0px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  width: 100% !important;
  height: 160px;
  box-shadow: 0px 10px 10px #2322220d;
}

.admin_dashboard_container .dashboard_admin_content_body .form-group label {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
  color: #ed1c24 !important;
}

.admin_dashboard_container .dashboard_admin_content_body .form-group input {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  color: #ed1c24 !important;
}

.admin_dashboard_container .dashboard_admin_content_body .form-group input:focus {
  box-shadow: none !important;
}

.admin_dashboard_container .admin_sidebar_item button {
  /* background: #ed1c24; */
  /* color: white !important; */
  border: none;
  width: 100%;
  padding: 20px 20px;
  font-weight: 500;
  transition: 0.3s;
}


/* media queries  */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .login_wrapper .col-lg-5 {
    display: none !important;
  }


}

/* Modern Users Page Styles */
.users-list-section {
  padding: 24px;
  margin-top: 20px;
}

.users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 20px 0;
}

.user-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.user-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.user-card-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.user-avatar {
  border-radius: 50%;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-avatar i {
  font-size: 24px;
  color: #6c757d;
}

.user-info {
  flex: 1;
}

.user-name {
  font-size: 18px;
  font-weight: 600;
  color: #343a40;
  margin: 0 0 4px;
}

.user-role {
  font-size: 13px;
  color: #d9534f;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}


.detail-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  color: #6c757d;
}

.detail-item i {
  font-size: 16px;
  width: 20px;
  color: #6c757d !important;
}

.detail-item span {
  font-size: 14px;
}

.user-actions {
  display: flex;
  gap: 12px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e9ecef;
}

.user-actions button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-btn {
  background: #f8f9fa;
  color: #6c757d;
}

.edit-btn:hover {
  background: #e9ecef;
  color: #343a40;
}

.delete-btn {
  background: #fff5f5;
  color: #d9534f;
}

.delete-btn:hover {
  background: #d9534f;
  color: white;
}

.loading-state,
.error-state,
.empty-state {
  text-align: center;
  padding: 48px 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.empty-state img {
  width: 200px;
  margin-bottom: 24px;
}

.empty-state h3 {
  font-size: 20px;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 8px;
}

.empty-state p {
  font-size: 16px;
  color: #6c757d;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #d9534f;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: #c9302c;
  transform: translateY(-1px);
}

.action-button i {
  font-size: 16px;
  color: white !important;
}

/* Modal Styles */
.add_user_div {
  max-width: 600px;
  margin: 0 auto;
}

.add_user_header {
  margin-bottom: 24px;
  text-align: center;
}

.add_user_header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #343a40;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  margin-bottom: 8px;
}

.form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-control:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 3px rgba(217, 83, 79, 0.2);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancel-button {
  padding: 10px 24px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background: white;
  color: #6c757d;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background: #f8f9fa;
}

.submit-btn {
  padding: 10px 24px;
  border: none;
  border-radius: 8px;
  background: #d9534f;
  color: white;
  font-weight: 500;
  transition: all 0.2s ease;
}

.submit-btn:hover {
  background: #c9302c;
}

/* Edit Account Styles */
.edit-account-container {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
}

.edit-account-card {
    background: white;
    border-radius: 12px;
    padding: 32px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.edit-account-header {
    text-align: center;
    margin-bottom: 32px;
}

.edit-account-header h2 {
    font-size: 28px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 24px;
}

.tab-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 32px;
}

.tab-button {
    padding: 12px 24px;
    border: none;
    background: transparent;
    color: #6c757d;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
}

.tab-button::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: transparent;
    transition: all 0.2s ease;
}

.tab-button.active {
    color: #d9534f;
}

.tab-button.active::after {
    background: #d9534f;
}

.edit-form {
    max-width: 500px;
    margin: 0 auto;
}

.edit-form .form-group {
    margin-bottom: 24px;
}

.edit-form label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    margin-bottom: 8px;
}

.edit-form .form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.2s ease;
}

.edit-form .form-control:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 3px rgba(217, 83, 79, 0.2);
    outline: none;
}

.edit-form .form-control:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
}

.form-actions {
    margin-top: 32px;
    text-align: center;
}

.btn-save {
    padding: 12px 32px;
    background: #d9534f;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-save:hover {
    background: #c9302c;
    transform: translateY(-1px);
}

.alert {
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 24px;
    font-size: 14px;
}

.alert-danger {
    background: #fff5f5;
    color: #dc3545;
    border: 1px solid #ffc7c7;
}

.alert-success {
    background: #f0fff4;
    color: #28a745;
    border: 1px solid #c6f6d5;
}