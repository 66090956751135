@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
  font-family: 'Poppins', sans-serif;
}

.wrapper-2 {
  width: auto;
  height: 100px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #00000080;
  opacity: 1;
  margin-left: 0px;
  font-weight: 600;
  font-size: 16px;
}

.logo-1,
.logo-2 {
  margin-top: 30px;
}

.upload-btn {
  margin-top: 20px;
  position: absolute;
  right: 3rem;
}

.logo-text {
  text-align: left;
  font: normal normal medium 12px/16px Poppins;
  font-size: 12px;
  color: #000000;
  opacity: 1;
}


.img {
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 20px;
}

.btn-wrap {
  padding: 9px 12px;
  border: 1px solid;
  color: black;
  text-transform: capitalize;
  cursor: pointer !important;
  margin: 5px 1px;
}

.wrapper-days {
  padding-left: 1rem;
  padding-top: 2rem;
  width: 600px;
}

.wrapper-days .btn {
  border-radius: 0px !important;
}

.wrapper-days .btn:focus {
  background: #ed1c24 !important;
  color: white;
  outline: none !important;
  /* border: none !important; */
  box-shadow: none !important;
}

.btn-bg {
  background-color: hsl(355deg 67% 96%);
  color: red;
}

.btn-default {
  width: 205px;
  height: 50px;
  background: #ed1c24 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  color: white;
  margin-left: 40px;
  margin-top: 4px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.btn-default:hover {
  background: #ed1c24 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  color: white;
  margin-top: 4px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  transform: scale(1.1);
}

.btn-default img {
  margin-right: 10px;
}

.buy_a_car .text-para-22 {
  position: absolute;
  top: 43px;
  left: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
}

.AddBtn {
  margin-top: 2rem;
  display: flex;
}

.AddBtn-btn {
  border: 0;
  color: red;
  padding: 15px;
  font-size: 16px;
  background-color: hsl(355deg 67% 96%);
  text-transform: capitalize;
  display: flex;
}

.AddBtn-btn:hover {
  text-decoration: none;
  transform: scale(1);
}

.text-para-33 {
  position: absolute;
  top: 10px;
  left: 33px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
}

.buy_a_car .fieldset-2 .wrapper {
  font-size: 14px !important;
}

.modal-button {
  padding: 10px 370px 10px 0px;
}

.MuiInput-root {
  padding: 0px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-style: normal !important;
  color: #aab5c2 !important;
}

.buy_a_car .MuiSelect-select.MuiSelect-select {
  padding: 10px 10px 10px 90px;
  width: 135px !important;
  color: black;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.buy_a_car #fieldset-2 .MuiSelect-select.MuiSelect-select {
  padding: 10px 10px 10px 20px;
  width: 135px !important;
  color: black;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.buy_a_car_bottom .MuiSelect-select.MuiSelect-select {
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 450px !important;
  height: 30px;
  color: black;
  background: #f5f6f7 0% 0% no-repeat padding-box;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.MuiSelect-select.MuiSelect-select em {
  font-style: normal !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fef3f3 !important;
  color: black !important;
  font-weight: 500 !important;
}

.buy_a_car .MuiListItem-root.Mui-selected::after {
  content: '✔' !important;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 50px;
  /* background-color: #ed1c24; */
  padding: 7px 15px;
  color: #ed1c24;
  transform: rotateZ(10deg);

}

.MuiMenuItem-root:hover {
  background-color: #ed1c24 !important;
  color: white !important;
}

.MuiInput-underline:before {
  content: "" !important;
  border-bottom: none !important;
}

.MuiInput-underline.Mui-focused:after {
  content: "" !important;
  border-bottom: none !important;
}

.buy_a_car #fieldset-2 .mul_cities .MuiSelect-select.MuiSelect-select {
  width: 400px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 30px;
  display: flex;
  align-items: center;
  background: #f5f6f7;
}

.buy_a_car #fieldset-2 .MuiSelect-select.MuiSelect-select {
  width: 948px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 30px;
  display: flex;
  align-items: center;
}



.buy_a_car #fieldset-2 span .MuiSelect-select.MuiSelect-select {
  font-weight: normal !important;
}

.buy_a_car .MuiButtonBase-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 20px !important;

}

.div_priorty .MuiSelect-select.MuiSelect-select {
  width: 200px !important;
}

.buy_a_car #fieldset-2 .set_availability .MuiSelect-select.MuiSelect-select {
  width: 120px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  background: #f5f6f7;
  padding-left: 80px;
  margin: 5px;
}

.buy_a_car .MuiInput-root {
  margin-right: 10px !important;
  background: #f5f6f7;
}

.buy_a_car_bottom .MuiInput-root {
  margin-right: 100px !important;
  background: #f5f6f7;
}

.buy_a_car .MuiListItem-button {
  width: 450px !important;
  padding-left: 45px !important;
}

.buy_a_car .MuiInputLabel-formControl {
  top: 4px;
  left: 25px;
  position: absolute;
  z-index: 1;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  color: black;
  opacity: 60%;
}

.buy_a_car .MuiFormLabel-root.Mui-focused {
  display: none !important;
}

.buy_a_car .MuiInputBase-root {
  display: inline;
}

.mul_cities .MuiSelect-icon {
  top: 15%;
}

.buy_a_car .action-button {
  position: relative !important;
  width: 180px !important;
  background-color: #ed1c24 !important;
  font-weight: 600;
  color: white;
  border: 0 none;
  border-radius: 6px;
  cursor: pointer;
  padding: 12px 24px;
  font-family: "Poppins", sans-serif;
  margin: 20px 0;
  float: right;
  transition: all 0.3s ease;
}

.buy_a_car .action-button:hover {
  background-color: #d01920 !important;
  transform: translateY(-2px);
}

.buy_a_car #fieldset-1 .action-button,
.buy_a_car #fieldset-2 .action-button,
.buy_a_car #fieldset-3 .action-button {
  position: relative !important;
  bottom: auto;
  right: auto;
  z-index: 1;
}

.buy_a_car .review-1 {
  width: 180px !important;
  margin: 20px 0;
  padding: 12px 24px;
}

@media screen and (max-width: 768px) {
  .buy_a_car .action-button,
  .buy_a_car .review-1 {
    width: 160px !important;
    padding: 10px 20px;
    font-size: 14px;
  }
}

.buy_a_car .input-11 {
  border: 0 !important;
  padding: 18px 44px !important;
  background: #f5f6f7 0% 0% no-repeat padding-box;
  margin-right: 5px;
  font-size: 1rem;
  height: 50px;
  width: 290px;
}

.guess_min {
  position: absolute;
  width: 242px !important;
  font-size: 12px !important;
  top: 55px;
  padding: 10px 15px;
}

/* Start of buy submit code  */

.buySubmit {
  padding: 0px 120px;
}

.buySubmit td span {
  font-weight: 500 !important;
  text-transform: uppercase;
}

.buySubmit table {
  width: 1100px !important;

}

.buySubmit .text-danger {
  font-weight: 600 !important;
}

.buySubmit .sell_submit_content_div {
  margin: 10px 0px 20px 0px;
}

.buySubmit .sell_submit_content_div h4 {
  font-size: 13px;
  font-weight: 500 !important;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.buySubmit .sell_submit_content_div p {
  font-size: 16px;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .buySubmit {
    padding: 0px 10px;
  }

  .years_select_div {
    display: flex;
    flex-direction: column;
    height: 108px;
    justify-content: space-around;
  }

  .buy_a_car_bottom {
    flex-direction: column;
    margin-top: 24px;
  }


  .buy_a_car_bottom .MuiSelect-select.MuiSelect-select {
    width: 200px !important;
  }

  .buy_a_car #fieldset-1 .action-button,
  .buy_a_car #fieldset-2 .action-button,
  .buy_a_car #fieldset-3 .action-button {
    right: 10px;
    bottom: 0.5rem;
    width: 137px;
    font-size: 14px;
  }

  .buy_a_car_bottom .MuiInput-root {
    margin-right: 0px !important;
  }

  .buy_a_car fieldset-3 .col-lg-12 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .buy_a_car .row {
    margin-top: 20px !important;
  }

  .buy_a_car #fieldset-2 .MuiSelect-select.MuiSelect-select {
    width: 220px !important;
  }

  .buy_a_car .contact_divs_column {
    flex-direction: column;
    margin-bottom: 5px;
  }

  .buy_a_car .contact_divs_column .wrap-1,
  .buy_a_car .contact_divs_column .wrap-2,
  .buy_a_car .contact_divs_column .wrap-3 {
    margin-bottom: 15px;
  }

  .buy_a_car .buy_notes_area {
    margin-top: 20px;
  }

  .buy_a_car .buy_notes_area textArea {
    margin-bottom: 80px;
  }

  .buy_a_car #fieldset-2 .mul_cities .MuiSelect-select.MuiSelect-select {
    width: 220px !important;
  }

  .buy_a_car .text-para-22 {
    top: 50px !important;
  }

  .submit_bottom_div .display-wrapper {
    /* padding-left: 0px !important; */
  }

  .address_div_mobile {
    flex-direction: column;
    margin-top: 0px !important;
  }

  .contact_divs_column {
    margin-bottom: 0px;
  }

  .buy-submit-bottom .display-wrapper {
    padding-left: 0px !important;
  }

  .buy-submit-bottom .display-wrapper .nav-btns {
    justify-content: flex-start !important;
  }


  .buy-submit-bottom .display-wrapper button {
    font-size: 12px;
    padding: 13px !important;
  }
}

/* Review Section Styles */
.review-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.title-text {
  text-align: center;
  margin-bottom: 3rem;
}

.title-text h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.title-text .subtitle {
  color: #666;
  font-size: 1.1rem;
}

.review-content {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.review-section {
  margin-bottom: 2.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.review-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.step-circle {
  width: 36px;
  height: 36px;
  background-color: #ed1c24;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  flex-shrink: 0;
}

.section-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  font-weight: 600;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.info-item {
  background: #f8f9fa;
  padding: 1.2rem;
  border-radius: 8px;
}

.info-item.full-width {
  grid-column: 1 / -1;
}

.info-item h4 {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.info-item p {
  color: #333;
  font-size: 1.1rem;
  margin: 0;
  word-break: break-word;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.action-buttons button {
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button {
  background: #fff;
  border: 1px solid #ed1c24;
  color: #ed1c24;
}

.back-button:hover {
  background: #fff5f5;
}

.submit-button {
  background: #ed1c24;
  border: none;
  color: white;
}

.submit-button:hover {
  background: #d01920;
}

@media (max-width: 768px) {
  .review-container {
    padding: 1rem;
  }

  .title-text h1 {
    font-size: 2rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons button {
    width: 100%;
  }
}

.steps-wrapper {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.steps-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  max-width: 800px;
  margin: 0 auto;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
}

.step-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 20px;
  right: -40px;
  width: 20px;
  height: 2px;
  background-color: #ddd;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
}

.step-number.active {
  background-color: #ed1c24;
  border-color: #ed1c24;
  color: white;
}

.step-text {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .steps-container {
    gap: 30px;
  }

  .step-item:not(:last-child)::after {
    right: -25px;
    width: 20px;
  }

  .step-number {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

  .step-text {
    font-size: 12px;
  }
}

.progress-steps-container {
  width: 100%;
  padding: 20px 0;
  background-color: #f8f8f8;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.progress-steps {
  display: flex;
  justify-content: center;
  gap: 80px;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
  cursor: pointer;
}

.progress-step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 25px;
  right: -50px;
  width: 20px;
  height: 2px;
  background-color: #ddd;
}

.progress-step .step-number {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  color: #666;
  transition: all 0.3s ease;
}

.progress-step.active .step-number {
  background-color: #ed1c24;
  border-color: #ed1c24;
  color: white;
}

.progress-step .step-text {
  font-size: 15px;
  color: #666;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.progress-step.active .step-text {
  color: #ed1c24;
  font-weight: 600;
}

@media (max-width: 768px) {
  .progress-steps {
    gap: 40px;
  }

  .progress-step:not(:last-child)::after {
    right: -30px;
    width: 20px;
  }

  .progress-step .step-number {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .progress-step .step-text {
    font-size: 13px;
  }
}

/* Remove old sticky progress bar styles */
#progressbar {
  position: static;
  bottom: auto;
  margin-bottom: 30px;
  overflow: visible;
  padding: 0;
}

#progressbar li {
  position: static;
}